export var selectedIndex = 0
export var selectedSubIndex = 0
export const setSelectedIndex = (idx:number) => {
    selectedIndex = idx
}
export const setSelectedSubIndex = (idx:number) => {
    selectedSubIndex = idx
}

export interface lastVisited {
    title?:string
    path?:string
}