import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
// import "./style.css";
import {Modalstyles} from "./style";
import Buttons from '../button';

interface ModalData{
    modalOpen?:boolean
    modalData?: React.ReactNode
    buttonClose?:()=>void
    buttonSave?:()=>void,
    saveBtnText?: string,
    cancelBtnText?: string
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Modal(props:ModalData) {
  
const classes = Modalstyles()

  return (
    <div className="modal-wrap">
      <Dialog onClose={()=>props.buttonClose ? props.buttonClose(): {}} aria-labelledby="customized-dialog-title" open={props.modalOpen ? props.modalOpen : false}>
        <DialogTitle id="customized-dialog-title" onClose={()=>props.buttonClose ? props.buttonClose(): {}}>
          {/* <h3 className="modal-heading">Modal title</h3> */}
        </DialogTitle>
        
        <DialogContent dividers>
          <Typography gutterBottom>
          {
          props.modalData ? props.modalData : "No data"
        }
          </Typography>
        </DialogContent>
        {
          props.buttonClose && props.buttonSave &&
          <DialogActions>
            <Buttons
            type="secondary" 
            label={ props?.cancelBtnText || "Cancel" }
            onClick={()=>props.buttonClose?props.buttonClose():{}}
            />
            <Buttons
            type="primary" 
            label={ props?.saveBtnText || "Ok" }
            onClick={()=>props.buttonSave?props.buttonSave():{}}/>
          </DialogActions>
        }
       
      </Dialog>
    </div>
  );
}
