import React from 'react';
import LoopIcon from '@material-ui/icons/Loop';
// import './style.css';
import {styles} from "./style";

function LoadingComponent(){
 const classes = styles()
    return(
      <div className={classes.loadingWrap}>
          <LoopIcon></LoopIcon>
      </div>
    )
}

export default LoadingComponent;
