import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';
export const styles  = makeStyles({
    autoCompleteWrap : {
          "& .MuiInputLabel-root":{
            color: theme.placeholder,
            fontSize: "13px",
            transform: "translate(14px, 12px) scale(1)",
            textTransform:"capitalize",
            fontFamily: 'Space Grotesk!important',
          },
          "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(15px, -6px) scale(0.88)",
            color: theme.inputLabel,
            fontSize:theme.smallSize,
            "&. Mui-error":{
                color: theme.errorField,
            }
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']":{
            padding: "0px",
            // border:1px solid @input-border;
            fontSize: theme.smallSize,
            "& .MuiAutocomplete-input":{
                textTransform: "capitalize",
                "& ::placeholder":{
                    color: theme.placeholder,
                    fontSize:theme.smallSize,
                },
                "& ::-webkit-input-placeholder" : { /* Chrome/Opera/Safari */
                    color: theme.placeholder,
                    fontSize:theme.smallSize,
                },
                "& ::-moz-placeholder"  :{ /* Firefox 19+ */
                color: theme.placeholder,
                fontSize:theme.smallSize,
                }
            }
        },
       "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":{
            padding: "12px",
            color: theme.white,
        },
        "& .MuiOutlinedInput-root":{
            fontFamily: 'Space Grotesk!important',
            "& .MuiOutlinedInput-notchedOutline":{
                border:"1px solid" + theme.inputBorder,
                "&. MuiAutocomplete-inputFocused":{
                    border:"1px solid" + theme.inputFocusColor,
                },
                "& legend":{
                    fontSize:theme.smallSize,
                }
            },
        },
        "& .MuiInputBase-root.Mui-disabled":{
            opacity:"0.6",
        },
        "& .sucessFormControl .MuiOutlinedInput-notchedOutline, & .sucessFormControl .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline" :{
            borderColor:theme.snackSuccess + "!important",
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .activeField .MuiOutlinedInput-notchedOutline":{
            borderColor: theme.white + "!important",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" :{
            border:"1px solid" + theme.inputFocusColor,
            "& label.MuiInputLabel-formControl":{
                color: theme.inputFocusColor,
            }
        },
        "& .search-icon":{
            position: "absolute",
            right: "10px",
            top: "20px",
            cursor: "pointer",
        },
        "& .MuiFormControl-marginNormal":{
            marginTop: "0px",
            marginBottom: "0px",
        },
        "& .MuiIconButton-label svg path":{
            fill: theme.white,
        }
      },
      
      "& .MuiAutocomplete-popper":{
        background: theme.white,
        padding: "0",
        "& ul":{
            padding: "0",
            "& li":{
                fontSize: theme.smallSize,
                'border-bottom': "1px solid" + theme.inputBorder,
                background: theme.white,
                padding: "5px 10px",
                "& :hover":{
                    background: theme.orange,
                    fontWeight: "700",
                }
            }
        }
    }
    });
