import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';

export const styles  = makeStyles({
    DatePickerWrap : {
        position:"relative",
            "& .MuiTextField-root":{
                display: 'flex',
                border:"1px solid" + theme.inputBorder,
                borderRadius: "4px",
                padding: "0px 15px",
                margin: "auto",
                "& .MuiInputBase-input":{
                    color: theme.white,
                    fontSize: theme.smallSize,
                    padding:"8.5px 0",
                    fontFamily: 'Space Grotesk!important',
                },
                "& .MuiInputLabel-shrink": {
                    top: "-6px",
                },
                "& .MuiInput-underline:after, & .MuiInput-underline:hover:not(.Mui-disabled):before":{
                    display: 'none'
                },
                "& label + .MuiInput-formControl":{
                    marginTop: "5px",
                },
                "& label":{
                    color: theme.placeholder,
                    left: "10px",
                    background: theme.mainBg,
                    padding: "0px 5px",
                    fontSize:theme.smallSize,
                    fontFamily: 'Space Grotesk!important',
                    top: "-12px",
                    "&.Mui-error":{
                        color:theme.errorField,
                    }
                },
                "& .MuiInput-underline:before":{
                    display:"none",
                },
                "& .MuiIconButton-root":{
                    padding:"12px 0px",
                },
                "& .MuiIconButton-label":{
                    "& svg":{
                        color: theme.inputBorder,
                        top: "-2px",
                        position: "relative",
                    }
                },
                "&.sucessFormControl" :{
                    border:"1px solid" + theme.snackSuccess,
                },
                "&.activeField" :{
                    border:"1px solid" + theme.white,
                    "& label":{
                        color:theme.placeholder,
                    }
                },
                // "&.dateError":{
                //     border:"1px solid" + theme.errorField,
                // }
            },
            "& .error-msg":{
                color: theme.errorField,
                position: "absolute",
                bottom: "-18px",
                left: "0px",
                fontSize: theme.smallSize,
                fontWeight: "400",
                textTransform: "capitalize",
            },
            "& .MuiFormHelperText-root.Mui-error":{
                display:"none",
            }
      
      },
      dateError:{
        border:"1px solid" + theme.errorField,
      }
    //   parent : {
    //         '@media (max-width: 1024px)' : {
    //             background: "transparent"  
    //         },
    //         background : "transparent"
    //   },
    //   error : {
    //         color: "red"
    //   }
  })

//   export const style = {
//       parent : {
//             background : "white"
//       }
//   }

  
