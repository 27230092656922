
// interface validatorProps{
//     email?: string
// }
export const  validateEmail = (val:string) => { 
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; 
    // if(props.email){
        return re.test(val); 
    // }
}

export const toTitleCase = (str: string) =>{
    let res = str.replace( /([A-Z])/g, " $1" );
    return res.charAt(0).toUpperCase() + res.slice(1);
}