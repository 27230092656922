import Button from '@material-ui/core/Button';
// import './style.css';
import {styles, style} from "./style";
import Icon from "@material-ui/core/Icon";

interface buttonProps{
  label?: string,
  type?: string,
  onClick?: () => void,
  icon?:string,
  disabled?: boolean,
  fullWidth?:boolean
  buttonType?:any
}


export default function Buttons(props: buttonProps) {
    //const classes = useStyles();
    const classes = styles();
    const svgIcon = (
      <Icon>
        <img alt={props.icon} src={props.icon} />
      </Icon>
    );

    return (
      <div className={classes.btnWrap}>
        <Button variant="contained" 
        color={props.type == "primary" ? "primary" : "secondary"} 
        className={`${props.type == "teritery" ? "teritery-btn" : ''} ${props.type == "primary" ? "primary-btn" : props.disabled ? "disabled-btn" : "secondary-btn"}`}
        disabled={props.disabled}
        startIcon={props.icon ? svgIcon : ''}
        onClick={props.onClick}
        fullWidth = {props.fullWidth}
        type={props.buttonType}
        >
          {props.label}
        </Button>
        {/* <Button variant="contained" color="secondary" className="secondary-btn">
          Secondary
        </Button>
        <Button variant="contained" disabled className="disabled-btn">
          Disabled
        </Button> */}
      </div>
    );
  }