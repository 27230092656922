import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
// import './style.css';
import {styles} from "./style";


interface snackProps {
  errorMsg?: any,
  successMsg?: string,
  warningMsg?: string,
  errorKey?: any,
  openToast?: boolean,
  type?:any,
  autoHideDuration?:number,
  toastClose?: ()=> void
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Snackbars(props: snackProps) {
  //const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    setOpen(false);
    if (reason === 'clickaway') {
      return;
    }
    if(props?.toastClose){
      props?.toastClose()
    }
  };

  const classes = styles()
  return (
    <div className={classes.snackBarWrap}>
      {/* <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button> */}
      <Snackbar
      open={props.openToast && open}
      onClose={handleClose}
      autoHideDuration={props.autoHideDuration}>
        <Alert onClose={handleClose} severity={props.type}>
        {props.errorMsg}
        </Alert>
      </Snackbar>
      {/* <Alert  severity="error">{props.key} {props.errorMsg}<i><img src="/assets/img/error-close.svg"/></i></Alert> */}
      {/* <Alert severity="warning">{props.warningMsg}<i><img src="/assets/img/warning-close.svg"/></i></Alert>
      <Alert severity="success">{props.successMsg}<i><img src="/assets/img/success-close.svg"/></i></Alert> */}
    </div>
  );
}
