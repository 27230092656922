import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';
export const Menustyles  = makeStyles({
    menuList : {
        "& .MuiButton-root": {
            color: theme.orange,
            textTransform: "capitalize",
            fontSize: theme.smallSize,
            marginLeft:"5px",
            textDecoration:"underline",
            cursor:"pointer",
            minWidth: "auto",
            padding:"2px",
            lineHeight: "19px",
            "&:hover": {
                color: theme.bgColorhover
            }
         },
         "& .MuiMenuItem-root":{
            fontFamily: 'Space Grotesk!important',
         }
        },
  })

  export const style = {
      parent : {
            background : "white"
      }
  }

  