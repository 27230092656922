import React from 'react';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
// import './style.css'
import {styles, style} from "./style";
import { ChipData } from '../../../pages/brokerDaily/interface';

interface chipProps {
  data?:ChipData[]
  onDelete?: (data:ChipData,index:number) => void
  chipsOnClick?: (idx:number)=>void
  key?:string
}

export default function ChipsArray(props: chipProps) {
  const classes = styles();
  const [chipData, setChipData] = React.useState<ChipData[]>([]);

  const handleDelete = (chipToDelete: ChipData,idx:number) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    if(props.onDelete){
      props.onDelete(chipToDelete,idx)
    }

  };

  return (
      <div className="chip-wrap">
    <Paper component="ul" className={classes.parent}>
      { props.data && props.data.map((data,idx) => {
        let icon;

        // if (data.label === 'React') {
        //   icon = <TagFacesIcon />;
        // }

        return (
          <li key={data.key} onClick={()=>props.chipsOnClick ? props.chipsOnClick(idx):{}}>
            <Chip
              icon={icon}
              label={data.label}
              key={props.key}
              onDelete={handleDelete(data,idx)}
              className={classes.root}
            />
          </li>
        );
      })}
    </Paper>
    </div>
  );
}
