import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import './style.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {styles} from "./style";
import clsx from 'clsx';


// const useStyles = makeStyles({
//   table: {
//     //minWidth: 650,
//   },
// });


interface tableProps{
    tableHeader?: React.ReactNode,
    tableBody?: React.ReactNode,
    filterShow?: boolean,
    filterCount?: boolean,
    showEmptycell?: boolean
}


const DenseTable: React.FC<tableProps> =(props: tableProps)=>{
  const classes = styles();
  return (
    <div className={props.filterShow ? clsx(classes.tableWrap,classes.tableFilterAdded) :  classes.tableWrap}>
      <PerfectScrollbar>
      <Table size="small" aria-label="a dense table" stickyHeader>
        <TableHead>
              <TableRow>
                {props.showEmptycell ? <TableCell></TableCell> : null}
                {props.tableHeader ? props.tableHeader: "no header data"}
              </TableRow>   
        </TableHead>
        <TableBody>
              {props.tableBody ? props.tableBody: "no header data"}
        </TableBody>
      </Table>
      </PerfectScrollbar>
    </div>
  );
}
export default DenseTable;