import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Textfield, Chip, AutoComplete, Select, Buttons ,Checkbox} from "../../components/UI-kit";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Login } from './dataModel';
import { setAccesstoken } from '../../utils/storage/mutation';
import { useHistory,useLocation } from 'react-router-dom';
import {styles} from './style';
import { login } from './interface';





// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     background: "#1D252E",
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: "#F1B924",
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
//   text:{
//     color:"#ffffff"
// }
// }));

export default function SignIn() {
  const classes = styles();
  const history = useHistory()
  const [userName,setUserName] = useState<string>()
  const [password,setPassword] = useState<string>()

  // window.localStorage.removeItem("accesstoken")  
  // window.localStorage.removeItem("visitedPaths")
  // window.localStorage.removeItem("paths")
  const {login,data} = Login()  
  console.log("data",data)
  // if(data?.authenticate) {
  //     setAccesstoken(data.authenticate.accessToken!)
  //     history.push({pathname:'/company'})
  // }

  return (

<Grid container item xs={12}>
<Grid item xs={5} className={classes.loginLeftSection}>
     <strong>
       Login
     </strong>
</Grid>
<Grid item xs={7} className={classes.loginRightSection}>
    <div className="login-form-wrap flex-wrap">
        <div className="logo">
          <img src="/assets/img/big-logo.png" />
        </div>
        <div className="login-form">
          <form>
            <div className="username">
              <Textfield
              label="Username"
              onChange={(val:string)=>setUserName(val)}
              placeholder="Username"/>
              <i><img src="/assets/img/user-icon.png"/></i>
            </div>
            <div className="password">
              <Textfield
              label="Password"
              inputType="password"
              onChange={(val:string)=>setPassword(val)}
              placeholder="Password"/>
               <i><img src="/assets/img/pass-icon.png"/></i>
            </div>
            <div className="remember">
              <Checkbox
              label={"Remember Me"}
              name={"Remember Me"}/>
            </div>
            <div className="submit-btn">
               <Buttons
               type="teritery"
               label="Login"
               onClick={()=>{
                       let AuthenticateUser = {
                         userName:userName,
                          grantType:"authorization_code", 
                          password:password
                       }
                         login(
                           {
                             variables:{ AuthenticateUser : AuthenticateUser}
                           }).then((res)=>{
                             let loginRes = res.data as login
                             if(loginRes) {
                               console.log("data login",loginRes)
                               setAccesstoken(JSON.stringify(loginRes))
                               history.push("/broker-daily")
                             }
                             
                           })
                        
                      }}/>
                      </div>
          </form>
        </div>
    </div>
</Grid>
</Grid>
    
  );
}