import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../global';
import loginBg from '../../img/login-bg.png';
import whiteBg from '../../img/login-text-bg.svg';
export const styles  = makeStyles({
    loginLeftSection:{
        height:"100vh",
        position:"relative",
        background: "url(" + loginBg + ")",
        backgroundSize:"100%",
        "& strong":{
            fontSize:"21px",
            color:"#010101",
            textTransform:"uppercase",
            padding:"54px 30px",
            position:"absolute",
            right:"-1px",
            top:"21%",
            fontWeight: "600",
            background:"url("+ whiteBg +")",
            backgroundSize:"100%",
        }
    },
    loginRightSection:{
        background:theme.white,
        height:"100vh",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        "& .login-form-wrap":{
            display:"flex",
            alignItems:"center",
            verticalAlign:"middle",
            width:"60%",
            justifyContent:"center",
            "& .logo":{
                width:"70%",
                marginBottom:"30px",
                "& img":{
                    width:"100%",
                }
            },
            "& .login-form":{
                display:"flex",
                width:"70%",
                "& form":{
                    width:"100%",
                    "& .username":{
                        marginBottom:"25px",
                        position:"relative",
                        "& .MuiFormControl-root .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
                            borderColor:theme.inputBorder,
                        },
                        "& .MuiFormControl-root label.MuiInputLabel-formControl":{
                            color:theme.black,
                        },
                        "& .MuiFormControl-root .MuiOutlinedInput-input":{
                            color:theme.black,
                            paddingRight:"30px",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
                            border:"1px solid" + theme.inputBorder,
                        },
                        "& i":{
                            position:"absolute",
                            right:"10px",
                            top: "10px",
                        }
                    },
                    "& .password":{
                        marginBottom:"10px",
                        position:"relative",
                        "& .MuiFormControl-root .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
                            borderColor:theme.inputBorder,
                        },
                        "& .MuiFormControl-root label.MuiInputLabel-formControl":{
                            color:theme.black,
                        },
                        "& .MuiFormControl-root .MuiOutlinedInput-input":{
                            color:theme.black,
                            paddingRight:"30px",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
                            border:"1px solid" + theme.inputBorder,
                        },
                        "& i":{
                            position:"absolute",
                            right:"10px",
                            top: "10px",
                            cursor:"pointer",
                        }
                    },
                    "& .submit-btn":{
                        "& div":{
                            display:"flex !important",
                            marginTop:"10px",
                        },
                        "& .teritery-btn":{
                            width:"100%",
                            height:"40px",
                        }
                    },
                }
            }
        }
    }
});