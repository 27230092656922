import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';

export const styles  = makeStyles({
    selectWrap : {
        position: "relative",
        display:"inline-flex",
        "& .MuiFormControl-root":{
            width: "100%",
            "& .MuiOutlinedInput-root":{
                padding: "5px 10px",
                borderRadius: "15px",
                background: theme.primaryColor,
                border:"0",
                margin: "0px 6px",
            },
            "& .MuiSelect-selectMenu":{
                fontSize: theme.smallSize,
                padding: "0px 15px 0px 5px",
                color:theme.white,
            },
            "& .MuiOutlinedInput-root.Mui-focused & .MuiOutlinedInput-notchedOutline":{
                border:"1px solid" + theme.textFocusColor,
            },
            "&.sucessFormControl .MuiOutlinedInput-notchedOutline" :{
                border:"1px solid" + theme.snackSuccess,
            },
            "& label.MuiInputLabel-formControl" :{
                fontSize: "13px",
                transform: "translate(12px, 14px) scale(1)",
            },
            "& .MuiInputLabel-outlined.MuiInputLabel-shrink":{
                transform: "translate(18px, -4px) scale(0.75)",
                color: theme.textFocusColor,
            }
        },
        "& .MuiSelect-iconOutlined":{
            right: "0",
            "& path":{
                fill: theme.white
        }
      }
    }
})