export const ROUTES = {
        LOGIN: "/",
        HOME: "/home",
        BROKER_DAILY: "/broker-daily",
        BROKER_DAILY_DETAIL: "/brokerdaily-detail",
        ADD_BROKERDAILY:"/add-brokerdaily",
        POSITIONS: "/positions",
        ADD_POSITIONS: "/add-positions",
        POSITIONS_DETAIL: "/positions-detail"
}

