import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';
export const styles  = makeStyles({
    inputWrap : {
            position: "relative",
            "& .MuiFormControl-root":{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline":{
                    borderColor: theme.inputBorder,
                    background: "transparent",
                    borderRadius: "4px",
                },
                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline" :{
                    borderColor: theme.white,
                },
                "&.activeField .MuiOutlinedInput-notchedOutline,  &.activeField & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline" :{
                    border:"1px solid" + theme.white,
                    borderColor:theme.white + "!important",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" :{
                    border:"1px solid" + theme.inputFocusColor,
                    "& label.MuiInputLabel-formControl" :{
                        color: theme.inputFocusColor,
                    },
                },
                "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline, & .sucessFormControl & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":{
                    border:"1px solid" + theme.errorField + "!important",
                },
                "& .MuiOutlinedInput-input":{
                    padding: "12px 16px",
                    fontSize: theme.smallSize,
                    height: "auto",
                    color: theme.white,
                },
                "& .MuiInputBase-root":{
                    lineHeight: "14px",
                    fontFamily: 'Space Grotesk!important',
                },
                "& label.MuiInputLabel-formControl":{
                    fontSize: theme.smallSize,
                    transform: "translate(12px, 12px) scale(1)",
                    color: theme.placeholder,
                    fontFamily: 'Space Grotesk!important',
                },
                "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                    transform: "translate(15px, -6px) scale(0.88)",
                    color: theme.inputLabel,
                    "&.Mui-error":{
                        color: theme.errorField,
                    }
                },
                "&.sucessFormControl .MuiOutlinedInput-notchedOutline, &.sucessFormControl & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline" :{
                    border:"1px solid" + theme.snackSuccess ,
                    borderColor:theme.snackSuccess + "!important",
                },
            },
            "& .error-msg":{
                color: theme.errorField,
                position: "absolute",
                bottom: "-18px",
                left: "0px",
                fontSize: theme.smallSize,
                fontWeight: "400",
                textTransform: "capitalize",
            },
            "& .input-check":{
                position: "absolute",
                top: "0px",
                right: "0px",
                cursor:"pointer",
                display:"none",
            }
        },
  })

  export const style = {
      parent : {
            background : "white"
      }
  }

  