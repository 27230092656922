import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';
export const styles  = makeStyles({
      root : {
        "& .MuiCheckbox-colorSecondary.Mui-checked":{
            color: theme.primaryColor,
        },
      },
      parent  :{
          "& .MuiFormControlLabel-label":{
                fontSize: "13px",
                color:theme.checkboxTextColor,
                fontFamily: 'Space Grotesk!important',
                fontWeight: "600"
            }
}
});

export const style = {
    parent : {
          background : "white"
    }
}