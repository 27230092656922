import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';

export const styles  = makeStyles({
      root : {
        position: 'relative',
        "& .input-field" :{
            width: '100%',
             "& ::placeholder":{
                color: theme.white,
                opacity: "1",
             },
            "& .MuiOutlinedInput-notchedOutline":{
                borderColor: theme.selectBorder,
                background: theme.darkGrey,
                borderRadius: '18px',
                boxShadow: '0px 3px 6px #00000066'
            },
            "& .MuiOutlinedInput-root:hover > .MuiOutlinedInput-notchedOutline" :{
                borderColor: theme.white,
            },
            "& .MuiOutlinedInput-root.Mui-focused > .MuiOutlinedInput-notchedOutline" :{
                border: "1px solid" + theme.inputFocusColor,
                "& label.MuiInputLabel-formControl":{
                    color: theme.inputFocusColor,
                }
            },
            "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":{
                border:"1px solid" + theme.errorField,
            },
            "& .MuiOutlinedInput-input":{
                padding: "12px",
                fontSize: theme.smallSize,
                height: 'auto',
                color: theme.white,
                zIndex:"1",
                fontFamily: 'Space Grotesk!important',
            },
            "& .MuiInputBase-root":{
                lineHeight: "14px",
            },
            "& label.MuiInputLabel-formControl":{
                fontSize: "13px",
                transform: "translate(12px, 12px) scale(1)",
                color: theme.white,
            },
            "& .MuiInputLabel-outlined.MuiInputLabel-shrink":{
                transform:"translate(15px, -6px) scale(0.88)",
                color: theme.inputLabel,
                "& .Mui-error":{
                    color:theme.errorField,
                }
            }
        },
        "& .error-msg":{
            color: theme.errorField,
            position: 'absolute',
            bottom: "-18px",
            left: "0px",
            fontSize: theme.smallSize,
            fontWeight: "400px",
            textTransform: 'capitalize'
        },
        "& .search-icon":{
            position: 'absolute',
            right: "15px",
            top: "11px",
            bottom: "0px",
            margin: 'auto',
            zIndex: "1",
            cursor: "pointer",
        }
      
      },
  })

  export const style = {
      parent : {
            background : "white"
      }
  }

  
