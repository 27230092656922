import React, { ReactNode } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import "./style.css";
import { MenuList } from "@material-ui/core";
import {styles} from "./style";

interface selectProps {
  value?: any;
  defaultValue? :any;
  onChange?: (value: boolean) => void;
  label?: string;
  menuList?: any[]
}

export default function SimpleSelect(props: selectProps) {
  const classes = styles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>,) => {
    if (props.onChange) {
      props.onChange(event.target.value as boolean);
    }
  };
  return (
    <div className={classes.selectWrap}>
      <FormControl variant="outlined">
        <InputLabel id="demo-simple-select-outlined-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={(e)=> handleChange(e)}
          label={props.label}
          // displayEmpty
        >
          <MenuItem value="" disabled>
            None
          </MenuItem>
         {
           props?.menuList?.map((item)=>{
            return <MenuItem value={item.value}>{item.label}</MenuItem>
           })
         }
        </Select>
      </FormControl>
    </div>
  );
}
