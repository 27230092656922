import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';
import UploadBg from '../../../img/upload-bg.png';
export const styles  = makeStyles({
    uploadWrap:{
            width: "100%",
            height: "150px",
            border:"2px dashed" + theme.inputBorder,
            color: theme.white,
            position:"relative",
            display:"flex",
            alignItems:"center",
            // marginTop:"25px",
            borderRadius:"5px",
            "&:hover":{
               background: "url("+UploadBg+")",
               "& em":{
                   color:theme.orange,
               },
               "& strong svg path":{
                   fill: theme.orange,
               }
            },
            "& .file-upload-label":{
                position:"absolute",
                top:"-25px",
                "& label":{
                    fontSize:theme.smallSize,
                    color:theme.inputLabel,
                }
            },
        "& input":{
            opacity:"0",
            position: "absolute",
            top: "0",
            bottom:"0",
            left:"0",
            right:"0",
            cursor:"pointer",
            width:"100%",
        },
        "& label":{
            width: "100%",
            "& strong":{
                width: "30px",
                margin: "0px auto",
                display: "block",
                textAlign: "center",
                "& svg":{
                    "& path":{
                        fill:theme.white,
                    }
                }
            },
            "& em":{
                display: "block",
                fontStyle:"normal",
                textAlign:"center",
                fontSize:theme.normalSize,
                textDecoration:"underline",
            },
            "& span":{
                display:"block",
                textAlign:"center",
                fontSize:theme.smallSize,
                "& i":{
                    display:"block",
                    fontStyle:"normal",
                }
            }
        }
    },
    afterUpload :{
        width: "100%",
        height: "150px",
        position:"relative",
        display:"flex",
        alignItems:"center",
        // marginTop:"25px",
        background:theme.blueBtnText,
        border:"2px solid" + theme.orange,
        borderRadius:"5px",
        "& .file-upload":{
            position:"absolute",
            height:"100%",
            width:"100%",
            display:"flex",
            alignItems:"center",
            "& strong":{
                width: "30px",
                margin: "0px auto",
                display: "block",
                textAlign: "center",
                position: "absolute",
                left: "0",
                right: "0",
                top: "35px",
                "& svg path":{
                    fill: theme.errorField,
                }
            },
            "& .file-name":{
                color:theme.black,
                fontSize:theme.smallSize,
                width:"100%",
                textAlign:"center",
                marginTop: "30px",
            },
            "& .delete-icon , & .download-icon":{
                position:"absolute",
                top:"5px",
                right:"5px",
                cursor:"pointer",
                "& svg":{
                    width:"15px",
                },
                "&:hover":{
                    "& svg":{
                        "& path":{
                            fill:theme.errorField,
                        }
                    }
                }
            },
            "& .download-icon":{
                right: "30px",
                "&:hover":{
                    "& svg":{
                        "& path":{
                            fill:theme.snackSuccess + "!important",
                        }
                    }
                }
            }
        },
        "& .file-upload-label":{
            position:"absolute",
            top:"-25px",
            "& label":{
                fontSize:theme.smallSize,
                color:theme.inputLabel,
            }
        },
    },
    Edit:{
        background:"transparent",
        border: "2px dashed" + theme.inputBorder,
        width: "100%",
        height: "150px",
        position:"relative",
        display:"flex",
        alignItems:"center",
        // marginTop:"25px",
        borderRadius:"5px",
        "& .file-upload":{
            position:"absolute",
            height:"100%",
            width:"100%",
            display:"flex",
            alignItems:"center",
            "& strong":{
                width: "30px",
                margin: "0px auto",
                display: "block",
                textAlign: "center",
                position: "absolute",
                left: "0",
                right: "0",
                top: "35px",
                "& svg path":{
                    fill:theme.white,
                },
            },
            "& .file-name":{
                color:theme.white,
                fontSize:theme.smallSize,
                width:"100%",
                textAlign:"center",
                marginTop: "30px",
            },
            "& .delete-icon , & .download-icon":{
                position:"absolute",
                top:"5px",
                right:"5px",
                cursor:"pointer",
                "& svg":{
                    width:"15px",
                    "& path":{
                        fill: theme.white,
                    }
                },
                "&:hover":{
                    "& svg":{
                        "& path":{
                            fill:theme.errorField,
                        }
                    }
                }
            },
            "& .download-icon":{
                right: "30px",
                "&:hover":{
                    "& svg":{
                        "& path":{
                            fill:theme.snackSuccess + "!important",
                        }
                    }
                }
            }
        },
        "& .file-upload-label":{
            position:"absolute",
            top:"-25px",
            "& label":{
                fontSize:theme.smallSize,
                color:theme.inputLabel,
            }
        },
    },
    EditUpload:{
        background:theme.editField,
        border:"2px solid" + theme.orange,
        width: "100%",
        height: "150px",
        position:"relative",
        display:"flex",
        alignItems:"center",
        // marginTop:"25px",
        borderRadius:"5px",
        "& .file-upload":{
            position:"absolute",
            height:"100%",
            width:"100%",
            display:"flex",
            alignItems:"center",
            "& strong":{
                width: "30px",
                margin: "0px auto",
                display: "block",
                textAlign: "center",
                position: "absolute",
                left: "0",
                right: "0",
                top: "35px",
                "& svg path":{
                    fill: theme.errorField,
                }
            },
            "& .file-name":{
                color:theme.black,
                fontSize:theme.smallSize,
                width:"100%",
                textAlign:"center",
                marginTop: "30px",
                fontWeight:"600"
            },
            "& .delete-icon , & .download-icon":{
                position:"absolute",
                top:"5px",
                right:"5px",
                cursor:"pointer",
                "& svg":{
                    width:"15px",
                },
                "&:hover":{
                    "& svg":{
                        "& path":{
                            fill:theme.errorField,
                        }
                    }
                }
            },
            "& .download-icon":{
                right: "30px",
                "&:hover":{
                    "& svg":{
                        "& path":{
                            fill:theme.snackSuccess + "!important",
                        }
                    }
                }
            }
        },
        "& .file-upload-label":{
            position:"absolute",
            top:"-25px",
            "& label":{
                fontSize:theme.smallSize,
                color:theme.inputLabel,
            }
        },
    }, 
    uploadError:{
        border:"2px solid" + theme.errorField,
        width: "100%",
        height: "150px",
        color: theme.white,
        position:"relative",
        display:"flex",
        alignItems:"center",
        // marginTop:"25px",
        borderRadius:"5px",
    }
})