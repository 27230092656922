import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';
export const styles  = makeStyles({
    longMenu : {
        "& .MuiIconButton-root": {
            color: theme.black,
            fontSize: theme.normalSize,
            textAlign: "center",
            transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            borderRadius: "50%",
            padding:"5px",
            backgroundColor: theme.orange,
            marginLeft:"15px",
            "&:hover": {
                backgroundColor: theme.bgColorhover
            }
         },
         "& .MuiMenuItem-root":{
            fontFamily: 'Space Grotesk!important',
         }
        },
  })

  export const style = {
      parent : {
            background : "white"
      }
  }

  