
import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';

export const Modalstyles  = makeStyles({
        "& div.MuiDialog-paper":{
            background:theme.white + "!important",
            "& .modal-heading":{
                fontSize: theme.headingSize,
                color: theme.black,
                margin: "0",
                fontWeight: "500",
                fontFamily: 'Space Grotesk!important',
            },
            "& .MuiDialogTitle-root":{
                padding: "10px",
                fontFamily: 'Space Grotesk!important',
            },
            "& .MuiDialogContent-root":{
                borderTop: "0px",
                borderBottom: "0px",
                padding:"0px 10px",
                fontFamily: 'Space Grotesk!important',
            },
            "& .MuiIconButton-root":{
                padding: "2px",
                "&.MuiSvgIcon-root":{
                    width: "20px"
                }
            }
        },
    //   parent : {
    //         '@media (max-width: 1024px)' : {
    //             background: "transparent"  
    //         },
    //         background : "transparent"
    //   },
    //   error : {
    //         color: "red"
    //   }
  })

//   export const style = {
//       parent : {
//             background : "white"
//       }
//   }

  
