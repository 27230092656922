import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';
export const styles  = makeStyles({
      formSelect : {
        position: "relative",
        width: "100%",
        "& .MuiFormControl-root":{
            width: "100%",
            "& .MuiInputBase-root":{
                padding: "5px 10px",
                border:"1px solid" + theme.inputBorder,
                marginTop: "0",
                borderRadius: "3px",
                "&:hover":{
                    border:"1px solid" + theme.white,
                }
            },
            "& .MuiInputLabel-formControl":{
                transform: "translate(12px, 16px) scale(1)",
                fontSize: "13px",
                color: theme.white,
                textTransform:"capitalize",
                fontFamily: 'Space Grotesk!important',
            },
            "& .MuiSelect-selectMenu":{
                fontSize: theme.smallSize,
                padding: "8px",
                color:theme.white,
                fontFamily: 'Space Grotesk!important',
            },
            "& .MuiInputBase-root.Mui-focused":{
                border:"1px solid" + theme.inputFocusColor,
            },
            "& .MuiInput-underline:after":{
                display:"none",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before":{
                borderBottom:"0px",
            },
            "& .MuiInputLabel-formControl.MuiInputLabel-shrink": {
                transform: "translate(15px, -6px) scale(0.88)",
                color: theme.inputLabel,
                background: theme.mainBg,
                zIndex:"2",
                padding: "0px 3px",
                "&. Mui-error":{
                    color: theme.errorField,
                }
            },
            "& .MuiSelect-icon":{
                right: "0",
                "& path":{
                    fill: theme.white
            }
          }
        },
      }
    });