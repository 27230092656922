import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';

export const styles  = makeStyles({
    snackBarWrap : {
            "& .MuiSnackbar-root":{
                left: "8px",
                right: "8px",
                display: "flex",
                zIndex: "1400",
                position: "static",
                alignItems: "center",
                justifyContent: "center",
                margin: "0px 0px 12px"
            },
            "& .MuiPaper-root":{
                borderRadius: "18px",
                padding: "0px 16px",
                position: 'relative',
                "& .MuiAlert-icon":{
                    display: 'none'
                },
                "& i":{
                    position: 'absolute',
                    right: "15px",
                    top: "8px",
                    cursor: 'pointer'
                }
            },
            "& .MuiAlert-filledError":{
                background: 'rgb(128,40,41)',
                background: 'linear-gradient(90deg, rgba(128,40,41,1) 0%, rgba(255,79,82,1) 100%)',
                border: "1px solid" + theme.snackError,
            },
            "& .MuiAlert-filledSuccess":{
                background: 'rgb(0,77,47)',
                background: 'linear-gradient(90deg, rgba(0,77,47,1) 0%, rgba(0,154,94,1) 100%)',
                border:"1px solid" + theme.snackError,
            },
            "& .MuiAlert-filledWarning":{
                background: 'rgb(110,65,13)',
                background: 'linear-gradient(90deg, rgba(110,65,13,1) 0%, rgba(219,130,26,1) 100%)',
                border:"1px solid" + theme.snackError,
            }
        }
     
    //   parent : {
    //         '@media (max-width: 1024px)' : {
    //             background: "transparent"  
    //         },
    //         background : "transparent"
    //   },
    //   error : {
    //         color: "red"
    //   }
  })


  