
import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';
export const styles  = makeStyles({
      root : {
            color: "red",
            // "& .MuiRadio-colorSecondary.Mui-checked" : {
            //       color: theme.primaryColor,
                  
            // },
            // "& .MuiFormControlLabel-label" : {
            //       fontSize: "13px",
            //       color:theme.checkboxTextColor,
            // }
            "& .MuiRadio-colorSecondary.Mui-checked" :{
                  color: theme.primaryColor,
              },
              "& .MuiFormControlLabel-label":{
                  fontSize: "13px",
                  color:theme.checkboxTextColor,
                  fontFamily: 'Space Grotesk!important',
              }
      
      },
     
      parent : {
            // '@media (max-width: 1024px)' : {
            //     background: "red"  
            // },
            // background : "white"
      },
      error : {
            color: "red"
      }
  })

//   export const style = {
//       parent : {
//             background : "white"
//       }
//   }

  
