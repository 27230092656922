import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  AutoComplete,
  Buttons,
  DatePickers,
  Select,
  Textfield,
  MultiSelect,
  Loading
} from "../../../components/UI-kit";
import { setLocalFilters } from "../../../utils/storage/mutation";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormatColorResetRounded } from "@material-ui/icons";
import moment from "moment";
import { autoCompleteOptions, fields, FieldTypes, pageRequest } from "../../../pages/brokerDaily/interface";

export interface filterProps {
  type?: string[];
  data?: fields[];
  onSubmit?: (data: fields[]) => (void)
  onClose?:()=>(void)
  multiSelectData?: string[]
  handleMultiSelect?: (value:string) => void
  multiSelectView?: React.ReactNode
  key?:string
  loading?:boolean

}

export default function                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    CompanyFilter(props: filterProps) {
  
  const [key,setKey] = useState<string>()
  const [isReset,setReset] = useState<boolean>(true)
  const [fieldset,setFields] = useState<fields[]>([])

  var pageRequest: pageRequest = {};
  const countryOptions: autoCompleteOptions[] = [];
  const stateOptions: autoCompleteOptions[] = [];
  const cityOptions: autoCompleteOptions[] = [];
  const assetOptions: autoCompleteOptions[] = []
  const securityOptions: autoCompleteOptions[] = []
  const booleanOptions: autoCompleteOptions[] = [{
    label:"Yes",
    id:"YES",
    value:"Yes"
  },{
    label:"No",
    id:"NO",
    value:"No"
  }]

  if (
    props.data
  ) {
    
    var field = JSON.parse(JSON.stringify(props.data)) //deep copy of array to change values
      
        if(fieldset.length != props.data.length) {
          setFields(field)
        }
       
     
  }

  const renderCountry = (
    item: fields,
    countryOptions: autoCompleteOptions[]
  ) => {
    return (
      <AutoComplete
        hideSearchIcon={true}
        label={item.description}
        defaultValue={item.name}
        // value={item.name}
        id={"filter_" + item.apiKey}
        onChange={(e: any,idx:number) => {
          if(e == null) {
            delete item.value
            return
          }
          item.value = countryOptions[idx].id
          item.name = e
        }}
        key={key}
        searchData={countryOptions || []}
      />
    );
  }


  const renderAssetType = (
    item: fields,
    assetOptions: autoCompleteOptions[]
  ) => {
    return (
      <AutoComplete
        hideSearchIcon={true}
        label={item.description}
        id={"filter_" + item.apiKey}
        defaultValue={item.name}
        onChange={(e: any,idx:number) => {
          item.value = assetOptions[idx].id
          item.name = e
        }}
        key={key}
        searchData={assetOptions || []}
      />
    );
  }

  const renderSecurityType = (
    item: fields,
    options: autoCompleteOptions[]
  ) => {
    return (
      <AutoComplete
        hideSearchIcon={true}
        label={item.description}
        defaultValue={item.name}
        id={"filter_" + item.apiKey}
        onChange={(e: any,idx:number) => {
         
          item.value = options[idx].id
          item.name = e
          console.log("selected value",options[idx],idx)
        }}
        key={key}
        searchData={options || []}
      />
    );
  }
  
  const renderState = (
    item: fields,
   
    stateOptions: autoCompleteOptions[]
  ) => {
    return (
      <AutoComplete
        hideSearchIcon={true}
        key={key}
        label={item.description}
        id={"filter_" + item.apiKey}
        defaultValue={item.name}
        onChange={(e: any,idx: number) => {
          item.value = stateOptions[idx].id
          item.name = e
        }}
        searchData={stateOptions || []}
      />
    );
  }


  


const renderCity = (
  item: fields,
  cityOptions: autoCompleteOptions[]
)=> {
  return (
    <AutoComplete
      hideSearchIcon={true}
      id={"filter_" + item.apiKey}
      label={item.description}
      key={key}
      defaultValue={item.name}
      onChange={(e: any,idx:number) => {
        item.name = e
        item.value = cityOptions[idx].id
        
      }}
      searchData={cityOptions || []}
    />
  );
}
  const renderInputType = (
    item: fields,
    idx: number
  ) => {
    if (item.inputType) {
      switch (item.inputType) {
        case FieldTypes.TEXTFIELD:
          return (
            <Textfield
              label={item.description}
              id={"filter_" + item.apiKey}
              defaultValue={fieldset[idx].value}  
              key={key}
              onChange={(e) => {
                let fields = fieldset
                fields[idx].value = e
                setFields(fieldset)
               console.log("selected idx",fieldset[idx].value)
              }}
            />
          );
        case FieldTypes.MULTISELECT:
          if (item.description == "Country") {
            return;
          }
          return <AutoComplete
          hideSearchIcon={true}
          key={key}
          id={"filter_" + item.apiKey}
          label={item.description}
          defaultValue={item.name}
          onChange={(e: any,idx: number) => {
            if(e == null) {
              delete item.value
              return
            }
            item.value = item.multiSelectOptions![idx].id
            item.name = e
          }}
          searchData={item.multiSelectOptions || []}
        />;
        case FieldTypes.ENUM:
          console.log('type Enum')
          return (
            <AutoComplete
              hideSearchIcon={true}
              key={key}
              id={"filter_" + item.apiKey}
              label={item.description}
              defaultValue={item.name || ""}
              value={fieldset[idx].value}
              onChange={(e: any,idx: number) => {
                if(e == null) {
                  delete item.value
                  return
                }
                item.value = item.multiSelectOptions![idx].value
                item.name = e
              }}
              searchData={item.multiSelectOptions || []}
            />
          );
        case FieldTypes.BOOLEAN:
         
          return <AutoComplete
          hideSearchIcon={true}
          key={key}
          id={"filter_" + item.apiKey}
          label={item.description}
          defaultValue={item.name}
          onChange={(e: any,idx: number) => {
            if(e == null) {
              delete item.value
              return
            }
            item.value = booleanOptions[idx].id
            item.name = e
          }}
          searchData={booleanOptions}
        />;
        case FieldTypes.SELECT:
          return <Select label={item.description} />;
        case FieldTypes.DATEPICKER:
          return <DatePickers 
          label={item.description} 
          defaultValue={item.value ? item.value: null}
          value={item.value ? item.value: null}
          id={"filter_" + item.apiKey}
          key={key}
          disabled={true}
          onChange={(e) => {
            let fields = fieldset
            fields[idx].value = moment(e).format("yyyy-MM-DD")
            setFields(fieldset)
            item.value = moment(e).format("yyyy-MM-DD")
            setKey(Math.random().toString())
            console.log("Date idx", item.value)
          }}/>;
        case FieldTypes.DATETIME:
          return <DatePickers 
          label={item.description} 
          defaultValue={item.value ? item.value: null}
          value={item.value ? item.value: null}
          id={"filter_" + item.apiKey}
          key={key}
          onChange={(e) => {
            let fields = fieldset
            fields[idx].value = moment(e).format("yyyy-MM-DD")
            setFields(fieldset)
            item.value = moment(e).format("yyyy-MM-DD")
            setKey(Math.random().toString())
            console.log("Date idx", item.value)
          }}/>;
      }
    }
  };

  console.log("props.multiSelectData",props.multiSelectData)

  return (
    <Grid item xs={3} className="filter-wrapper">
      
      <PerfectScrollbar>
      <form>
{
  !props.loading ?   <div className="filter-section">
  <b>Filter By</b>
  {/* <b>{props.key}</b> */}
  <i className="close-icon" onClick={props.onClose}>
    <img src="/assets/img/white-close.svg" />
  </i>
  
  <div className="filetr-option full-content">
    <span className="clear-filter" onClick={()=>{
      if(fieldset && props.onSubmit){
      
        fieldset.map((item)=>{
            delete item.value
            delete item.name
        })
        setKey(Math.random().toString()) //changing the key rerenders the input component to reset it
        props.onSubmit(fieldset)
        console.log('onsubmit', fieldset);
        // if(props.onClose){
        //   props.onClose()
        // }
      }
      
      console.log("itemmmmmmmmmmmmm",fieldset)
    }}>Clear Filter</span>
    {/* <span className="add-filter">Add Filter</span> */}
    <span className="add-filter">
          <MultiSelect
              multiSelectValues={props.multiSelectData}
              onHandleMultiselectValue={(value: any) => props.handleMultiSelect ? props.handleMultiSelect(value) : {} }
              multiSelectValue={props.multiSelectView}
              label={"Add Filter"}
            />
    </span>
  </div>
  <ul>
    { fieldset 
      ? fieldset?.map((item, idx) => {
          if (item.description == "Country") {
            return (<li>{renderCountry(item, countryOptions)}</li>);
          }
          if(item.description == "State") {
            return (<li>{renderState(item,stateOptions)}</li>)
          }
          if(item.description == "City") {
            return (<li>{renderCity(item,cityOptions)}</li>)
          }
          // if(item.apiKey == "assetType") {
          //   return (<li>{renderAssetType(item,assetOptions)}</li>)
          // }
          // if(item.apiKey == "securityType") {
          //   return (<li>{renderSecurityType(item,securityOptions)}</li>)
          // }
          return (
            <li>{renderInputType(item, idx)}</li>
          );
        })
      : <Loading/>}
  </ul>
  <div className="filter-bottom">
  <Buttons
      label={"Reset"}
      type={"secondary"}
      buttonType={"reset"}
      onClick={()=>{
        // let fields = fieldset
        fieldset.map((item)=>{
         
            delete item.value
          
            // delete item.value
            delete item.name
          
        })
        setReset(false)
        setFields(fieldset)
        // if(props.onClose){
        //   props.onClose()
        // }
        setKey(Math.random().toString())
        // console.log("onSubmit=====>",fieldset)
      }}
    />
    <Buttons
      label={"Search"}
      type={"primary"}
      buttonType={"submit"}
      onClick={() =>{
          if(props.onSubmit) {
            fieldset.map((item)=>{
              delete item.reference
            })
            props.onSubmit(fieldset)
            console.log("onSubmit=====>",fieldset)
          }
        }}
    />
  </div>
</div> : <Loading/>
}
      </form>
      </PerfectScrollbar>
      
    </Grid>
  );
}
