import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
// import "./style.css";
import {styles} from "./style";


interface paginationProps{
  handleChange?: (e:any, value:number) => void,
  totalPage?: number
}

export default function BasicPagination(props:paginationProps) {
  const handleChange = (event:any, value:number) => {
    if(props.handleChange){
      props.handleChange(event,value)
    }
  };
  const classes = styles()

  return (
    <div className={classes.PaginationWrap}>
      <Pagination
      count={(props?.totalPage ? props?.totalPage : 1)}
      color={'primary'} onChange={handleChange}/>
    </div>
  );
}
