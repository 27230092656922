import { gql } from '@apollo/client'

export const UPDATE_DYNAMICFIELDS = gql`mutation ($menuEncId: String, $fields: String){
    updateDynamicFields(menuEncId: $menuEncId, fields: $fields) {
        fields {
            encId
            description
            inputType
            alias
            reference
            field
            apiKey
            sortReq
            searchField
        }
        checkedFields {
            encId
            description
            inputType
            alias
            reference
            field
            apiKey
            searchField
            sortReq
        }
    }
}
`
export const ADD_COMPANY_MUTATION = gql`
mutation($CompanyInput:CompanyInput){
    addCompany(dto:$CompanyInput){
      companyName
      companyCode
    }
}
    `
export const UPDATE_COMPANY_MUTATION = gql`
mutation($CompanyInput:CompanyInput){
    updateCompany(dto:$CompanyInput){
        companyName
        companyCode
    }
}
    `

export const DELETE_DAILY_INTEREST = gql`
mutation ($encId: String) {
    deleteDailyInterest(
        encId: $encId
    )
}
`