import { makeStyles, withStyles } from '@material-ui/core';

export const mainBg = '#1D252E';
export const primaryColor = '#0059FF';
export const secondaryColor = '#FAFAFA';
export const tableHead = '#11161A';
export const tableEvenRow = '#151C22';
export const boldText =  '#003554';
export const textColor =  '#6D7088';
export const checkboxTextColor =  '#363636';
export const tabColor =  '#D4F3FA';
export const inputFocusColor = '#FBEABC';
export const textFocusColor = '#2C95AF';
export const inputBorder = '#676767';
export const white = '#ffffff';
export const black = '#000000';
export const headingSize = '18px';
export const normalSize = '14px';
export const smallSize = '12px';
export const orange = '#F1B924';
export const tableCellHover = '#F1B9244D';
export const commonBorder = '#47484B';
export const tableBorder = '#222328';
export const multiSelectBg = '#F7FAFF';
export const multiSelectBorder = '#D3E2EB';
export const multiSelectListBorder = '#E9EDF5';
export const selectBorder = '#3E3F41';
export const chipBorder = '#FCC110';
export const buttonPrimary = '#F1B924';
export const inputLabel = '#B4B2B2';
export const errorField = '#EA4435';
export const placeholder = '#ffffffcc';
export const snackError = '#F5C0C0';
export const snackSuccess = '#01C77A';
export const snackWarning = '#FFD8AA';
export const blueBtnText = '#FDF7E7';
export const greyHeading = '#FFFFFFD9';
export const darkGrey = '#171E25';
export const caretColor = '#909597';
export const bgColorhover = '#f5b408';
export const editField = '#B2FFE1';

export const fullContent = makeStyles({
    fullContent: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        alignItems: "center",
    }
  });

export const flexDiv = makeStyles({
    flexWrapDiv: {
            display: "flex",
            flexWrap: "wrap",
    }
})

  