
import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';

export const styles  = makeStyles({
    multiSelectWrap : {
    "& .MuiFormControl-root" :{
        display: 'flex',
        background: "#171E25",
        width: '160px',
        "& .MuiInputBase-root" :{
            display: 'flex',
            padding: "2px 0px",
            margin: "0px",
            border: "1px solid" + theme.selectBorder,
            borderRadius: "3px",
            height: "36px",
            boxShadow: "0px 3px 6px #00000066",
            "&.Mui-focused":{
                border:"1px solid" + theme.inputFocusColor,
            },
            "&:hover":{
                border:"1px solid" + theme.white,
            }
        },
        "& .MuiFormLabel-root":{
            fontSize: "12px",
            color:theme.white,
            left: "12px",
            transform: "translate(0, 12px) scale(1)",
            fontFamily: 'Space Grotesk!important',
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before" :{
            display: "none",
        },
        "& .MuiInput-underline:before":{
            display:"none",
        }
    },
    "& .MuiSelect-select.MuiSelect-select":{
        opacity: "0"
    },
    "& .MuiInputLabel-shrink":{
        transform:"translate(0, 24px) scale(1)",
    },
    "& .MuiInputBase-input":{
        padding: "2px 5px",
    },
    "& .MuiInput-underline.Mui-focused:after" :{
        display: "none",
    },
    "& .MuiSelect-icon path" :{
        fill: theme.caretColor,
    }
    },
        "&.MuiPopover-paper":{
            // maxHeight: "350px",
             "& li.MuiMenuItem-root":{
                paddingTop: "3px",
                paddingBottom: "3px",
                 borderBottom:  "1px solid" + theme.multiSelectListBorder,
                 background: theme.white,
                 "& .MuiCheckbox-colorSecondary":{
                     padding: "3px 2px",
                 },
                 "& .MuiListItemText-root":{
                    marginTop: "2px",
                    marginBottom: "2px",
                     "&.MuiTypography-body1":{
                         fontSize: theme.normalSize,
                         color:theme.checkboxTextColor,
                     },
                 },
                 "& .MuiCheckbox-colorSecondary.Mui-checked":{
                     color: theme.primaryColor,
                 },
                 // &:nth-of-type(2n){
                 //     background: @multi-select-bg;
                 // }
                 "&:hover":{
                     background: theme.white,
                 },
             },
             
             "& li.MuiListItem-root.Mui-selected, & li.MuiListItem-root.Mui-selected:hover":{
                 backgroundColor: theme.white,
             }
         },
         customDropdown:{
                marginTop: "80px",
                maxHeight: "300px",
                marginLeft: "0px",
                width: "160px",
                top: "20px !important",
                "& li":{
                    listStyletype: 'none',
                    display: 'inline-flex',
                    width:"100%"
                },
                "& .MuiListItem-root.Mui-selected":{
                    background:theme.white + "!important",
            },
            "& .MuiMenu-list":{
                paddingTop:"0",
                paddingBottom:"0",
            }
    
},
})

//   export const style = {
//       parent : {
//             background : "white"
//       }
//   }

  