import 'date-fns';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import './style.css';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {styles} from "./style";

interface dateInputProps {
  value?: string | null;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  label?: string;
  defaultValue?: string | null;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  fieldError?:string;
  onChange?: (e: string) => void;
  inputRef?: any
  ref?:any
  key?:string
  margin?:any
  classes?: string,
  id?:string
}

export default function DatePickers(props: dateInputProps) {
// The first commit of Material-UI
// const [selectedDate, setSelectedDate] = React.useState<Date | null>();

// const handleDateChange = (date: Date | null) => {
//   setSelectedDate(date);
// };

  function onChangeValue(date: Date | null) {
    if (props.onChange) {
      if(date){
        // setKey(Math.random().toString())
        props.onChange(date.toString());
      }
    
    }
  }

  const classes = styles()
  return (
    <div className={classes.DatePickerWrap}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid >
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={props.label}
          defaultValue={props.defaultValue}
          value={props.value}
          onChange={onChangeValue}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputProps={{ readOnly: true }}
        />
        </Grid>
        </MuiPickersUtilsProvider>
    </div>
  );
}
