/* eslint-disable array-callback-return */
import { Grid } from "@material-ui/core";
import  { useState } from "react";
import { Chip, MenuListComposition,} from "../../../components/UI-kit";
import { ChipData } from "../../../pages/brokerDaily/interface";
import { lastVisited } from "../header/dataModel";



export interface LastVisitedprops {
    history?:any
}

function LastVisitedComponent(props:LastVisitedprops){
    let paths : lastVisited[] = []

if(localStorage.getItem("lastVisited") != null){
  try {
    paths = JSON.parse(localStorage.getItem("lastVisited")!) as lastVisited[]
  }catch(e) {

  }
  // 

}else{
  let data: lastVisited = {title:"Company", path:"/company"}
  paths.push(data)
  localStorage.setItem("lastVisited",JSON.stringify(paths))
  
}

var chipDataLV : ChipData[] = []
var set = new Set<lastVisited>(paths)
var arr = Array.from(set)
arr.map((item,idx)=>{
  if(idx !== paths.length - 1) {
    chipDataLV.push({label:item.title,key:item.path})
  }

})


function deletChip(idx:number) {
  // if(arr.length == 2){
  //   localStorage.removeItem("lastVisited")
  //   return
  // }
  console.log("deleted data",chipDataLV)
  let data = arr.splice(idx,1)
  console.log("deleted data",data,arr)
  localStorage.setItem("lastVisited",JSON.stringify(arr))
  // let str = ""
  // chipDataLV.map((item)=>{
  //   str += "," + item.label
  // })
   
  // localStorage.setItem("paths",str)
  setKey(Math.random().toString())

}

    const [key,setKey] = useState("")
    
    return(
        <Grid item xs={6} className="last-visited">
          {
            chipDataLV.length !== 0 &&  <strong>Last Visited :</strong>
          }
       
        {/* {
                chipDataLV.length !== 0 && <Chip 
                                            key={key}
                                            data={chipDataLV.slice(0,3)} 
                                            chipsOnClick={(idx)=>{props.history.push(chipDataLV[idx].key)}}
                                            onDelete={(data,idx)=>{deletChip(idx)}}
              /> 
              }
              {
                chipDataLV.length > 3 &&  <MenuListComposition 
                                            chipsOnClick={(idx)=>{props.history.push(chipDataLV[idx + 3].key)}} 
                                            data={chipDataLV.slice(3,chipDataLV.length)}
                                            onDelete={(data,idx)=>{deletChip(idx + 3)}}/>
              } */}
       </Grid>
    )
}

export default LastVisitedComponent;