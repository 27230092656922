import { isDarkModeVar,localFilters,accessToken, holidayFilters, settlementFilters, accountExecutiveFilters, blotterFilters, officeFilters, glcoaFilters, exchangeCodeFilters, brokerFilters, subOfficeFilters, costCenterFilters } from "./localStorage";


export const toggleDarkMode = () => {
  const current = isDarkModeVar();
  isDarkModeVar(!current);
  window.localStorage.setItem('isDarkMode',!current == true ? "true" : "false")
};

export const setLocalFilters = (data: string) => {

  localFilters(data)
  window.localStorage.setItem("localFilters",data)
}

export const setHolidayFilters = (data: string) => {
  holidayFilters(data)
  window.localStorage.setItem("holidayFilters",data)
}

export const setSettlementFilters = (data: string) => {
  settlementFilters(data)
  window.localStorage.setItem("settlementFilters",data)
}

export const setaccountExecutiveFilters = (data: string) => {
  accountExecutiveFilters(data)
  window.localStorage.setItem("accountExecutiveFilters",data)
}

export const setBlotterFilters = (data: string) => {
  blotterFilters(data)
  window.localStorage.setItem("blotterFilters",data)
}
export const setOfficeFilters = (data: string) => {
  officeFilters(data)
  window.localStorage.setItem("officeFilters",data)
}

export const setGlcoaFilters = (data:string) => {
  glcoaFilters(data)
  window.localStorage.setItem("glcoaFilters",data)
}

export const setExchangeCodeFilters = (data: string) => {
  exchangeCodeFilters(data)
  window.localStorage.setItem("exchangeCodeFilters",data)
}
export const setBrokerFilters = (data: string) => {
  brokerFilters(data)
  window.localStorage.setItem("brokerFilters",data)
}
export const setSubOfficeFilters = (data: string) => {
  subOfficeFilters(data)
  window.localStorage.setItem("subOfficeFilters",data)
}
export const setCostCenterFilters = (data: string) => {
  costCenterFilters(data)
  window.localStorage.setItem("costCenterFilters",data)
}
export const setAccesstoken = (token: string) => {
  token = "dummy_token";
  accessToken(token)
  window.localStorage.setItem("accesstoken",token)
}

