import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from "../../../global";
import rowBg from '../../../img/bg-row.png';

export const styles  = makeStyles({
    tableWrap : {
            overflow: 'auto',
            position: 'relative',
            height: 'calc(100vh - 175px)',
            "& .MuiTable-root":{
                whiteSpace: 'nowrap',
                borderCollapse: "inherit",
                // border:"1px solid" + theme.inputBorder,
                borderRadius:"5px",
                borderBottom:"0px",
                "& .MuiTableHead-root":{
                    backgroundColor: theme.tableHead,
                    boxShadow: '0px 6px 10px #000000A5',
                    borderTopLeftRadius:"10px",
                    borderTopRightRadius:"10px",
                    position:"sticky",
                    top:"0",
                    zIndex:"1",
                    "& th":{
                        fontWeight: '500',
                        backgroundColor: theme.tableHead,
                        color: theme.white,
                        fontSize: theme.normalSize,
                        padding: "8px 8px",
                        borderBottom: "0px",
                        fontFamily: 'Space Grotesk!important',
                        "& span.column-sort":{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            cursor: 'pointer',
                            height: "22px",
                            width:"15px",
                            "& span":{
                                "&.sort-arrow-up":{
                                    marginRight:"-5px",
                                },
                                "&.clicked":{
                                    "& svg path":{
                                        fill:theme.orange,
                                    },
                                },
                                "&.sort-arrow-up svg":{
                                    transform: "rotate(180deg)",
                                }
                            }
                        },
                        "& em":{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            fontStyle: 'normal',
                            marginLeft: "5px",
                        },
                        "&:first-child":{
                             borderTopLeftRadius:"5px",
                        },
                        "&:last-child":{
                             borderTopRightRadius:"5px",
                        }
                    }
                },
                "& .MuiTableBody-root":{
                    "& .MuiTableRow-root":{
                        borderLeft:"1px solid " + theme.tableBorder,
                        borderRight: "1px solid " + theme.tableBorder,
                        borderRadius: "5px",
                        "&:nth-of-type(even)":{
                            background: theme.tableEvenRow,
                        },
                        "&:hover":{
                            boxShadow: '0px 0px 70px #000000A7',
                            backgroundImage: "linear-gradient(120deg, #2c2c2c 70%, #564a2b 80%)",
                            backgroundAttachment: "fixed",
                            "& td, & th":{
                                borderBottom:"1px solid #F1B9244D" ,
                                borderTop:"1px solid #F1B9244D",
                                "&:first-child":{
                                  borderTopLeftRadius: "5px",
                                  bordeTopLeftRadius: "5px",
                                    "&::before":{
                                        left: "0px",
                                        position: 'absolute',
                                        top: "0px",
                                        bottom: "0px",
                                        margin: 'auto',
                                        width: "4px",
                                        background: theme.orange,
                                        content: '""',
                                        borderRadius: "3px 0px 0px 3px",
                                    }
                                },
                                "&:last-child":{  
                                  borderTopRightRadius: "5px",
                                  borderBottomRightRadius: "5px",
                                  borderRight:"1px solid #F1B9244D",
                                }
                            }
                        },
                        "& td, & th":{
                            fontSize: theme.smallSize,
                            color: theme.white,
                            padding: "8px 8px",
                            borderBottom: "1px solid" + theme.tableBorder,
                            borderTop: "1px solid" + theme.tableBorder,
                            position: 'relative',
                            fontWeight: 400,
                            maxWidth: "150px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontFamily: 'inherit !important',
                            "&.link":{
                               color:theme.inputFocusColor,
                               textDecoration:"underline",
                               cursor:"pointer",
                            },
                            "&:first-child":{
                               // borderLeft:"1px solid" + theme.inputBorder,
                            },
                            "&:last-child":{
                               // borderRight:"1px solid" + theme.inputBorder,
                            },
                            "& .MuiIconButton-root":{
                                padding:"0px",
                                marginLeft:"0px",
                                background:"transparent",
                                color:theme.white,
                                "&:hover":{
                                    background:theme.inputFocusColor,
                                    color:theme.black,
                                }
                            }
                        },
                        "&:last-child":{
                           "& td":{
                               "&:first-child":{
                                   borderBottomLeftRadius:"5px",
                               },
                               "&:last-child":{
                                borderBottomRightRadius:"5px",
                            }
                           }
                        }
                    }
                }
            },
           
        },
        tableFilterAdded :{
            height: 'calc(100vh - 235px)',
        }
     
    //   parent : {
    //         '@media (max-width: 1024px)' : {
    //             background: "transparent"  
    //         },
    //         background : "transparent"
    //   },
    //   error : {
    //         color: "red"
    //   }
  })

  export const style = {
      parent : {
            background : "white"
      }
  }

  
