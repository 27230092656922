import { makeStyles } from '@material-ui/core';
import * as theme from '../../../global';
export const styles  = makeStyles({
    footerSection : {
        background: theme.mainBg,
        boxShadow: "0px -3px 6px #00000031",
        position: "fixed",
        bottom: "0",
        padding: "10px",
        right: "15px",
        zIndex:"-1",
        "& .footer-right":{
            textAlign: "right",
            "& .btn-wrapper":{
                marginLeft: "15px",
            }
        }
      }
    });