import React, { Suspense } from 'react';
import './App.css';
import './global.css';
import { HashRouter, Route, Switch, useHistory, Redirect } from "react-router-dom";
import browserHistory from 'react-router';


// pages
import Home from "./pages/home";
import BrokerDaily from "./pages/brokerDaily";
import AddBrokerDailyComponent from "./pages/brokerDaily/add-brokerdaily/add-brokerdaily"
import BrokerDailyDetail from './pages/brokerDaily/brokerdaily-detail/brokerdaily-detail';
import Positions from "./pages/positions";
import AddPositionComponent from "./pages/positions/add-positions/add-positions"
import PositionDetail from './pages/positions/positions-detail/positions-detail';



import SignIn from "./pages/login"
//routing paths
import { ROUTES } from "./routes";

// graphQL connection
import {
  ApolloClient,
  createHttpLink,
  ApolloProvider,
  ApolloLink,
  from,
  InMemoryCache,
  // ApolloLin

} from '@apollo/client'
import { onError } from '@apollo/client/link/error';

import { cache } from './utils/storage/cache';
import { asyncMap } from "@apollo/client/utilities";
import { accessToken } from './utils/storage/localStorage';
import { Snackbars } from './components/UI-kit';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { Color } from './utils/colorConstant';
import { toTitleCase } from './utils/validators';





const authMiddleware = new ApolloLink((operation, forward) => {
  const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJST0xFIjpbXSwic3ViIjoiN0xISmpjS0xFblFIa2haTUxid09IQSIsImF1ZCI6Imlvbml4IiwiaXNzIjoiZHciLCJleHAiOjE2MjA0MDg2NDUsImp0aSI6IjE2MjA0MDg2NDU5ODAifQ.SDIg-u1UG1dJmqKfZ32WFICiTK9xlllGh2fYc_IsSAc"
  // receving context set in mutation. you can set anyting in context and get here
  const customHeaders = operation.getContext().hasOwnProperty("headers")
    ? operation.getContext().headers
    : {};
  // add the authorization to the headers
  operation.setContext({
    headers: {
      Authorization: accessToken() ? `Bearer ${accessToken()}` : "",
      ...customHeaders
    }
  });
  return forward(operation)
});


//After the backend responds, we take the refreshToken from headers if it exists, and save it in the cookie.
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {

    const context = operation.getContext()
    const {
      response: { headers }
    } = context


    if (response.data) {

      // response.data['test'] = "sdfsdf"
      // console.log("res", response['data'])
    }
    return response
  })
})

interface error {
  field: string,
   error: string
}
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors != undefined && graphQLErrors[0]?.extensions) {
      if(graphQLErrors[0]?.extensions.error) {
      var arr: error[] = graphQLErrors[0].extensions.error
      arr = arr.map((item,idx)=>{
        
        return {...item,field:toTitleCase(item.field)}
        
      })
      
          graphQLErrors[0].extensions.error = arr
      
     
      console.log("errorr",
      graphQLErrors,
      arr,
      toTitleCase("inCamelCase")
    )
      }
      graphQLErrors?.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path},`,
        ),
      );
    }
  if (networkError) {
    // history.push("/")
    // <Redirect to={ROUTES.LOGIN}/>
    console.log(`[Network error]: ${networkError}`);
  }
});


function App() {




  const theme = createMuiTheme({
    palette: {
      primary: { main: Color.primary }, // Purple and green play nicely together.
      secondary: { main: '#FAFAFA' }, // This is just green.A700 as hex.
    },
  });
  
  const httpLink = createHttpLink({
    uri: 'http://54.236.56.8:3007/graphql',
    credentials: 'same-origin'
  });
  const fileLink = createHttpLink({
    uri: 'http://54.236.56.8:3006/graphql',
    credentials: 'same-origin'
  });
  const preLogin = createHttpLink({
    uri: 'http://54.236.56.8:3002/graphql',
    credentials: 'same-origin'
  });

  const otherLinks = ApolloLink.split(  
    operation => operation.getContext().clientName === "pre-login", // Routes the query to the proper client
    preLogin,
    httpLink
  );


  const client = new ApolloClient({
    link:  from([authMiddleware, afterwareLink, errorLink, ApolloLink.split(
      operation => operation.getContext().clientName === "file-link",
      fileLink, // <= apollo will send to this if File-Link is "file-link"
      otherLinks // <= otherwise will send to this
    ) ]),
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions: {
      mutate: { errorPolicy: 'ignore' },
    },
  });




  return (
    <>
    <ApolloProvider client={client}>
      <HashRouter>
      <MuiThemeProvider theme={theme}>
        <Suspense fallback={<div className="page-loader">
          Loading ...
        </div>}>
          <Switch>
            <Route exact path={ROUTES.LOGIN} component={SignIn} />
            <Route exact path={ROUTES.HOME} component={Home} />
            <Route exact path={ROUTES.BROKER_DAILY} component={BrokerDaily} />
            <Route exact path={ROUTES.ADD_BROKERDAILY} component={AddBrokerDailyComponent} />
            <Route exact path={ROUTES.BROKER_DAILY_DETAIL} component={BrokerDailyDetail} />
            <Route exact path={ROUTES.POSITIONS} component={Positions} />
            <Route exact path={ROUTES.ADD_POSITIONS} component={AddPositionComponent} />
            <Route exact path={ROUTES.POSITIONS_DETAIL} component={PositionDetail} />
          </Switch>
        </Suspense>
        </MuiThemeProvider>
      </HashRouter>
    </ApolloProvider>
    </>
  );
}

export default App;
