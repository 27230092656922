import { makeVar } from '@apollo/client';

export const isDarkModeVar = makeVar(localStorage.getItem('isDarkMode') == "true" ? true : false);
export const localFilters = makeVar(localStorage.getItem("localFilters"))
export const holidayFilters =  makeVar(localStorage.getItem("holidayFilters"))
export const settlementFilters =  makeVar(localStorage.getItem("settlementFilters"))
export const accountExecutiveFilters =  makeVar(localStorage.getItem("accountExecutiveFilters"))
export const blotterFilters =  makeVar(localStorage.getItem("blotterFilters"))
export const officeFilters =  makeVar(localStorage.getItem("officeFilters"))
export const glcoaFilters =  makeVar(localStorage.getItem("glcoaFilters"))
export const exchangeCodeFilters =  makeVar(localStorage.getItem("exchangeCodeFilters"))
export const brokerFilters =  makeVar(localStorage.getItem("brokerFilters"))
export const subOfficeFilters =  makeVar(localStorage.getItem("subOfficeFilters"))
export const costCenterFilters =  makeVar(localStorage.getItem("costCenterFilters"))


export const accessToken = makeVar(localStorage.getItem("accesstoken"))

