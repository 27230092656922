
export interface dynamicFields {
    getDynamicFields? : getDynamicFields
}
export interface getDynamicFields {
        fields?: fields[],
        checkedFields? : fields[]
}
export interface fields {
    encId?: string
    description?: string
    inputType?: FieldTypes
    alias?: string
    reference?: string,
    field ?: string,
    apiKey ?: string
    value?:string
    name?:string,
    searchField?: String,
    sortReq?: Boolean,
    defaultVisibility?: Boolean
    multiSelectOptions?: autoCompleteOptions[]
}

export enum FieldTypes {
    TEXTFIELD = "TEXT",
    SELECT = "SELECT",
    MULTISELECT = "MULTI_SELECT",
    DATEPICKER = "DATE",
    BOOLEAN = "BOOLEAN",
    DATETIME = "DATETIME",
    ENUM = "ENUM"
}
export interface dailyInterestList {
    encId?: string
    fileName?: string
    fileDate?: string
    vendorName?: string
    type?: string
    createdBy?: string
    createdAt?: string
}

export interface getAllDailyInterests {
    dailyInterestList?: dailyInterestList[],
    pageInfo?: pageRequest
}

export interface getBrokerDaily {
    getAllDailyInterests? : getAllDailyInterests
}
export interface dailyInputDetailList {
    encId: string
    secIdLink: string
    symbol: string
    date: string
    quantity: string
    ccy: string
    amount: string
    loanRate: string
    customerSplitRate: string
    commissionSplitRate: string
    customerRevenue: string
    commissionRevenue: string
}
export interface getdailyInterestDetailInput {
    dailyInterestDetailList?: dailyInputDetailList[]
    pageInfo?: pageRequest
}
export interface getBrokerDailyDetail {
    getAllDailyInterestDetails?: getdailyInterestDetailInput
}
export interface allMenu {
    getAllMenu?: menu[]
}

export interface menu {
    encId : string     
    menu: string
}
export interface dynamicFieldRequest {
    menuEncId?: string
    fields?: string
    screenType?: string
}

export interface pageRequest {
    page?: number,
    pageSize?: number,
    totalPage?:number,
    totalRecords?: number,
    offset?: number,
    limit?: number,
    sort?: string,
    sortOrder?: string,
    search?: string,
    filter?: string,
    skipPagination?: boolean,
    keywordSearch?: string,
    searchWithFields?: string,
    encId?: string
}
export interface autoCompleteOptions {
    id?: string
    label? : string
    value? : string
}
export interface updatedFields {
    updateDynamicFields?: getDynamicFields
}

export interface deleteFields {
    encId?: string
}


//field errors interface

export interface errorFields {
errorType?: string
extensions?: string
fieldErrors?: error[]
locations?: string
message: string
path: string
}

export interface error {
    field?:string,
    error?:string
}

/* chip */
export interface ChipData {
    key?: string;
    label?: string;
}