import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {styles} from "./style";

const options = [
  'None',
  'Atria',
  'Callisto',
  'Dione',
  'Ganymede',
  'Hangouts Call'
];

const ITEM_HEIGHT = 48;
interface LongMenu {
  options?: string[],
  onClick?: (e: string) => void
}

export default function LongMenu(props:LongMenu) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = styles()

  const handleMenuClick = (option: string) => {
    setAnchorEl(null);
    if(props.onClick) {
      props.onClick(option);
    }
  }

  return (
    <div className={classes.longMenu}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        className={"optionMenu"}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {props?.options?.map((option) => (
          <MenuItem key={option} onClick={() => handleMenuClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
