import React, { useState, useEffect, useMemo } from "react";
import HeaderComponent from "../../../components/common/header";
import { Grid, Paper } from "@material-ui/core";
import { DatePickers,FormSelect,InputUpload ,AutoComplete,Modal} from "../../../components/UI-kit";
import FooterComponent from "../../../components/common/footer";
import { useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";

import { validateEmail } from "../../../utils/validators";
import { useHistory } from "react-router-dom";
import { errorField } from "../../../global";
import { styles } from "../../style/style";
import { flexDiv } from "../../../global";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import LastVisitedComponent from "../../../../src/components/common/lastVisited";


function AddBrokerDailyComponent() {

    /* classes for syles  */
    const classes = styles();
    const flexdiv = flexDiv();
  
    /* useparam hooks */ 
    const location = useLocation();
    const history = useHistory();
  
    /* state for sidebar open and close */
    const [showMenu, setShowMenu] = useState<boolean>(false);
  
    /* handling the state for form errors */
    const [state, setState] = useState({
      companyNameError: "",
      companyCodeError: "",
      MPIDError: "",
      DTCNoError: "",
      TaxIDError: "",
      AddressLine1Error: "",
      AddressLine2Error: "",
      CountryError: "",
      StateError: "",
      CityError: "",
      ZipcodeError: "",
      ContactError: "",
      PhoneError: "",
      EmailError: "",
      FaxError: "",
    });
    /* updated knwon state*/
    const [updated, setUpdated] = useState<boolean>(false);

      /* handling the state for toast, error and model */
        const [toast, setToast] = useState<boolean>(false);
        const [error, setError] = useState<string>("");
        const [modalOpen, setModalOpen] = useState<boolean>(false);
        const [key, setKey] = useState("");
        let selectedCountryEncId: string = "";

    return (
        <div className={clsx(classes.addCompanyWrap, flexdiv.flexWrapDiv)}>

          <HeaderComponent showMenu={(show) => setShowMenu(show)} />
          <Grid className="main-content-wrapper" item xs={showMenu ? 9 : 11}>
            <Grid container spacing={2} className="add-company-section">
              <div className="top-section full-content">
                <Grid item xs={6}>
                  <h2 className="breadcrumb-heading">
                    <i>
                      <img
                        src="/assets/img/back-arrow.svg"
                        onClick={() => {
                          history.push("/broker-daily");
                        }}
                      />
                    </i>
                   
                    <strong>Add Broker Daily</strong>
                    <span
                      className="breadcrumb pointer"
                      onClick={() => {
                        history.push("/broker-daily");
                      }}
                    >
                      {" "}
                      Broker Daily
                    </span>{" "}
                    <span className="breadcrumb">Security Lending</span>
                  </h2>
                </Grid>
                <LastVisitedComponent history={history} />
              </div>
              <div className="main-content full-content">
                <ul className="form-extra-info">
                  <li className="mandatory-text">* - Mandatory Fields</li>
                  {/* {qs !== null && (
                    <>
                      <li className="mandatory-text">
                        <i>
                          <img src="/assets/img/input-check.svg" />
                        </i>{" "}
                        - Version History
                      </li>
                      <li className="mandatory-text changes">- Changes Made</li>
                    </>
                  )} */}
                </ul>
                <PerfectScrollbar className="add-page-scroll">
                  <Grid item xs={12} className="full-content form-section">
                    <Grid item xs={2} className="label-section">
                      <label>Broker Import Details</label>
                    </Grid>
                    <Grid item xs={10} className="input-form-section">
                      <Paper className="full-content">
                        <Grid item xs={4} className="input-wrapper">
                          <FormSelect/>
                          {/* <AutoComplete/> */}
                        </Grid>
                        <Grid item xs={4} className="input-wrapper">
                          <DatePickers/>
                        </Grid>
                        <Grid item xs={4} className="input-wrapper">
                        </Grid>
                        <Grid item xs={4} className="input-wrapper document-wrapper">
                          <InputUpload/>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} className="full-content form-section">
                    <Grid item xs={2} className="label-section">
                      <label>Communication Details</label>
                    </Grid>
                    <Grid item xs={10} className="input-form-section">
                      <Paper className="full-content">
                        <Modal
                          modalOpen={modalOpen}
                          buttonSave={() => {
                            setModalOpen(false);
                          }}
                          buttonClose={() => {
                            setModalOpen(false);
                            history.push("/company");
                          }}
                          modalData={
                            "The Changes made have not been saved. Are you sure want to go back without saving?"
                          }
                        />
                      </Paper>
                    </Grid>
                  </Grid> */}
                    <Modal
                      modalOpen={modalOpen}
                      buttonSave={() => {
                        setModalOpen(false);
                      }}
                      buttonClose={() => {
                        setModalOpen(false);
                        history.push("/broker-daily");
                      }}
                      modalData={
                        "The Changes made have not been saved. Are you sure want to go back without saving?"
                      }
                    />
                </PerfectScrollbar>
              </div>
            </Grid>
            <FooterComponent
            //   onSave={onAddCompanySave}
              onCancel={() => setModalOpen(true)}
              buttonSaveName={"Save"}
              buttonCancelName={"Cancel"}
            //   buttonDisabled={buttonDisable()}
              buttonType="primary"
            />
          </Grid>
        </div>
      );

}

export default AddBrokerDailyComponent;
