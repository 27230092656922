/* eslint-disable array-callback-return */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
// import './style.css';
import { styles } from "./style";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useHistory } from "react-router-dom";
import { menus, menuTitle } from "../../../utils/screens";
import {lastVisited, selectedIndex,selectedSubIndex,setSelectedIndex, setSelectedSubIndex} from "./dataModel"
interface headerProps {
  // showSideMenu?:boolean
  showMenu?: (show: boolean) => void;
}

export default function HeaderComponent(props: headerProps) {
  const classes = styles();
  const [showSideMenu, setShowSideMenu] = useState<boolean>(false);
  const [selectedIdx, setSelectedIdx] = useState(selectedIndex);
  // const [selectedSubIdx, setSelectedSubIdx] = useState(selectedSubIndex);


  const history = useHistory();


  return (
    <Grid className="header-main-wrapper" item xs={showSideMenu ? 3 : 1}>
      <div className={classes.menuWrap}>
        <Toolbar>
          <img src="/assets/img/logo.png" alt=""/>
        </Toolbar>
        <PerfectScrollbar>
          <AppBar id="simple-menu">
            <ul className="menu-list">
              {menuTitle.map((item, idx) => {
                return (
                  <li
                    className={selectedIdx === idx ? "active" : ""}
                    onClick={() => {
                      if (selectedIdx !== idx) {
                       
                        setShowSideMenu(true);
                        setSelectedIdx(idx);
                        setSelectedIndex(idx)
                        if (props.showMenu) {
                          props.showMenu(true);
                        }
                        return;
                      }
                      setShowSideMenu(!showSideMenu);

                      if (props.showMenu) {
                        props.showMenu(!showSideMenu);
                      }
                    }}
                  >
                    <span className="upper-curve"></span>
                    <span className="bottom-curve"></span>
                    <em>
                      <img src={item.normalImage} className="normal" alt=""/>
                      <img src={item.hoverImage} className="hover"  alt=""/>
                      <img src={item.activeImage} className="active"  alt=""/>

                      <strong>{item.title}</strong>
                    </em>
                  </li>
                );
              })}
            </ul>
            <span className="user-settings">
              <a href="javascript:void(0)">
                <img src="/assets/img/user-settings.svg"  alt=""/>
              </a>
            </span>
          </AppBar>
        </PerfectScrollbar>
        {showSideMenu && (
          <div className="sub-menubar">
            <i
              className="close-icon"
              onClick={() => {
                setShowSideMenu(!showSideMenu);
                if (props.showMenu) {
                  props.showMenu(!showSideMenu);
                }
              }}
            >
              <img src="/assets/img/white-close.svg"  alt=""/>
            </i>
            <strong>{menuTitle[selectedIdx].title}</strong>
            <ul className="sub-menu-list">
              {menus[selectedIdx] &&
                menus[selectedIdx].screens!.map((item: any,idx) => {
                  return (
                    <li className={selectedSubIndex === idx ? classes.selectedMenu : ""}>
                      <a
                        href={"javascript:void(0)"}
                        onClick={() => {
                          // setSelectedSubIdx(idx)
                          setSelectedSubIndex(idx)
                          let plv : lastVisited[] = []
                          if(localStorage.getItem("lastVisited") != null) {
                            plv = JSON.parse(localStorage.getItem("lastVisited")!) as lastVisited[]
                            let already = false
                            plv.map((pItem,idx) => {
                              if(pItem.title === item.title) {
                                already = true
                              }
                            })
                            if(already) {
                              history.push({ pathname: item.path })
                              return
                            }
                          }
                          // // let plv = JSON.parse(localStorage.getItem("lastVisited")) as lastVisited[]
                          let lv : lastVisited = {title:item.title,path:item.path}
                          plv.push(lv)
                          localStorage.setItem("lastVisited",JSON.stringify(plv))
                          history.push({ pathname: item.path })
                        }}
                      >
                        {" "}
                        {item.title}
                      </a>
                    </li>
                  );
                })}

              {/* <li><a href="#">Partner Profile</a></li>
            <li><a href="#">Partner Rate Card</a></li>
            <li><a href="#">Office</a></li>
            <li><a href="#">Account Master</a></li> */}
            </ul>
          </div>
        )}
      </div>
    </Grid>
  );
}
