
import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';

export const styles  = makeStyles({
    PaginationWrap : {
            "& .MuiPagination-ul" : {
                "& li" :{
                    color: theme.white,
                    fontFamily: 'Space Grotesk!important',
                    "& button.MuiPaginationItem-textPrimary.Mui-selected" :{
                        backgroundColor: theme.primaryColor,
                    },
                    "& button.MuiPaginationItem-page" :{
                        color:theme.white,
                        minWidth: "25px",
                        height:"25px",
                    }
                }
            }
      },
  })


  