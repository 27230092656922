import { Grid } from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import { Loading, LongMenu, Modal, Snackbars } from "../../components/UI-kit";
// import "./style.css";

//materil UI need for dynamic field creation
import TableCell from "@material-ui/core/TableCell";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";

// common view 
import ViewComponent from "../";

import {
  dynamicFieldRequest,
  pageRequest,
  fields,
  updatedFields,
  ChipData,
  deleteFields,
  dailyInterestList
} from "./interface";

import {GetMenuList, GetDynamicFields, fieldReq, setSearchText, searchText, setPageReq, pageReques, GetAllDailyInterest, typeOptions, brokerOptions } from "./dataModel";
import { DELETE_DAILY_INTEREST, UPDATE_DYNAMICFIELDS } from "./mutation";
import { localFilters } from "../../utils/storage/localStorage";
import { setLocalFilters } from "../../utils/storage/mutation";


import { SortableContainer, SortableElement } from "react-sortable-hoc";
import moment from "moment";
//Use the react-sortable-hoc wrappers around the matui elements


function BrokerDaily() {
  const [req, setReq] = useState<pageRequest>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [tableHeader, setTableHeader] = useState<string[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [fileToast, setFileToast] = useState(false)
  const [msg, setMsg] = useState("")
  const [filterLoading, setFilterLoading] = useState(false)
  // modal open
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [deleteSelection, setDeleteSelection] = useState<dailyInterestList>()
  var pageReq: pageRequest = {};
  pageReq.page = 1;
  pageReq.pageSize = pageSize;
  if (pageReques.searchWithFields) {
    pageReq.searchWithFields = pageReques.searchWithFields
  }
  if (pageReques.sort) {
    pageReq.sort = pageReques.sort
    pageReq.sortOrder = pageReques.sortOrder
  }
  var menuId: string = "";
  const history = useHistory();
  const location = useLocation();
  // var successMsg = ""
  useEffect(() => {

    return () => {
      setSearchText("")
      pageReq.search = ""
      pageReq.sort = ""
      setPageReq(pageReq)
    }



  }, [])
  if (location.state != undefined) {
    var state: any = location.state as Object;
    console.log("test add", state.fileSuccess)
    if (state.fileSuccess && !fileToast) {
      // successMsg = state.fileSuccessMsg
      setFileToast(true)
      setMsg(state.fileSuccessMsg)
    }
    var getSuccessToast = location?.state;
    console.log(getSuccessToast)

  }
  //query to get the list of menu-id

  var menuList = GetMenuList("broker daily");

  // const resMenu: allMenu = menuList as allMenu

  if (fieldReq.menuEncId) {
    menuId = fieldReq.menuEncId
  }

  var dynamicFieldsList = GetDynamicFields();
  var getDynamicFieldList: fields[] = []
  if (dynamicFieldsList?.getDynamicFields?.checkedFields?.length != 0 && dynamicFieldsList?.getDynamicFields?.checkedFields != undefined) {
    getDynamicFieldList = dynamicFieldsList?.getDynamicFields?.checkedFields
  } else if (dynamicFieldsList?.getDynamicFields?.fields != undefined) {
    getDynamicFieldList = dynamicFieldsList?.getDynamicFields?.fields?.filter((item) => item.defaultVisibility == true)
  }

  const [updateTableButton, { data: muttated, loading: mutationLoading }] = useMutation<updatedFields>(
    UPDATE_DYNAMICFIELDS
  );

  const [deleteTableButton, { data: deleted, loading: deletedLoading }] = useMutation<deleteFields>(
    DELETE_DAILY_INTEREST
  );

  const menuClick = (e: string, data: any, idx: number) => {
    if(e === 'Delete') {
      if(fileToast) {
        setFileToast(() => false)
      }
      setModalOpen(true);
      setDeleteSelection(data);
    }
  }

  const confirmDelete = () => {
    deleteTableButton({
      variables: {
        encId: deleteSelection?.encId
      },
    }).then(() => {
      if(!fileToast) {
        setFileToast(true)
        const msg = `The file ${deleteSelection?.fileName} has been deleted successfully!`;
        setMsg(msg)
      }
      pageReq.page = 1;
      setReq(pageReq);
      refetch();
    })
  }

  var chipData: ChipData[] = [];
  var localFilter: fields[] = []
  var updateReq: dynamicFieldRequest = {};

  if (getDynamicFieldList.length != 0) {


    if (muttated?.updateDynamicFields?.checkedFields != undefined && muttated?.updateDynamicFields?.fields != undefined) {

      getDynamicFieldList =
        muttated?.updateDynamicFields.checkedFields?.length === 0
          ? muttated?.updateDynamicFields.fields
          : muttated?.updateDynamicFields.checkedFields;
    }



    if (localFilters() != undefined && localFilters() != "") {
      localFilter = JSON.parse(localFilters()!);
      //  var found = false
      //  if(localFilter.length > getDynamicFieldList.length) {

      //     localFilter.map((item,idx)=>{
      //       found = false

      //           found = getDynamicFieldList.filter((e)=>e.apiKey == item.apiKey).length > 0

      //       if(!found) {
      //         console.log("found",idx,item)
      //         localFilter.splice(idx,1)
      //       }
      //     })
      //  }
      //  console.log("filters",localFilter,getDynamicFieldList)
      var newData: fields[] = [];
      var checkedTableList: any[] = getDynamicFieldList.map((item, idx) => {
        if (localFilter[idx] == undefined && idx != 0) {
          localFilter.splice(idx, 0, item)
        }


        if (localFilter[idx].encId == item.encId && localFilter[idx].value) {

          if (localFilter[idx].name != null && localFilter[idx].name) {
            chipData.push({
              label:
                localFilter[idx].description + " : " + localFilter[idx].name,
              key: localFilter[idx].encId,
            });
          } else {
            chipData.push({
              label:
                localFilter[idx].description + " : " + localFilter[idx].value,
              key: localFilter[idx].encId,
            });
          }

          newData.push({
            ...item,
            value: localFilter[idx].value,
            name: localFilter[idx].name,
          });
        } else {
          newData.push(item);
        }
        return item.description;
      });

      if (newData) {
        getDynamicFieldList = newData;
      }
      if (tableHeader.length === 0) {
        setTableHeader([...checkedTableList]);
      }
    } else {
      var checkedTableList: any[] = getDynamicFieldList!.map((item, idx) => {
        return item.description;
      });
      if (tableHeader.length === 0) {
        setTableHeader([...checkedTableList]);
      }
    }
  }


  if (getDynamicFieldList && getDynamicFieldList?.length != 0) {
    pageReq.filter = JSON.stringify(getDynamicFieldList);
  }

  if (getDynamicFieldList && getDynamicFieldList?.length != 0 && !req) {
    setReq(pageReq);
  }

  var { allDailyinterestList, interestLoading, refetch } = GetAllDailyInterest(
    req,
    getDynamicFieldList
  );
  var interestTableList = allDailyinterestList?.getAllDailyInterests?.dailyInterestList;

  getDynamicFieldList = getDynamicFieldList.map((item, idx) => {
    if (item.apiKey === "type" && item.inputType === "ENUM" && getDynamicFieldList[idx]) {
      return {
        ...item,
        multiSelectOptions: typeOptions
      }
    } else {
      return item
    }
  });
  getDynamicFieldList = getDynamicFieldList.map((item, idx) => {
    if (item.apiKey === "vendorName" && item.inputType === "ENUM" && getDynamicFieldList[idx]) {
      return {
        ...item,
        multiSelectOptions: brokerOptions
      }
    } else {
      return item
    }
  });

  const tableHeaderRender = () => {
    var pageSort = allDailyinterestList?.getAllDailyInterests?.pageInfo?.sort
    if (pageSort) {
      var pageSortParsed = JSON.parse(pageSort);
    }
    var pageInfo = allDailyinterestList?.getAllDailyInterests?.pageInfo
    return (
      getDynamicFieldList &&
      getDynamicFieldList?.map((field: any) => {
        return (
          <TableCell>
            {field.sortReq && (
              <>
                <span className="column-sort">
                  {
                    pageSortParsed?.searchField === field.searchField && pageInfo?.sortOrder === "asc" ?
                      <span onClick={() => {
                        let obj = { alias: field.alias, searchField: field.searchField };
                        pageReq.sortOrder = "desc";
                        pageReq.sort = JSON.stringify(obj);
                        setReq(pageReq)
                        setPageReq(pageReq)
                      }} className={pageSortParsed?.searchField === field.searchField && pageInfo?.sortOrder === "asc" ? "sort-arrow-up clicked" : "sort-arrow-up"}>
                        <img src="/assets/img/sort-up.svg" />
                      </span> :
                      pageSortParsed?.searchField === field.searchField && pageInfo?.sortOrder === "desc" ?
                        <span onClick={() => {
                          let obj = "";
                          pageReq.sortOrder = "";
                          pageReq.sort = obj;
                          setReq(pageReq);
                          setPageReq(pageReq)
                        }} className={pageSortParsed?.searchField === field.searchField && pageInfo?.sortOrder === "desc" ? "sort-arrow-down clicked" : "sort-arrow-down"}>
                          <img src="/assets/img/sort-down.svg" />
                        </span> :
                        <span onClick={() => {
                          let obj = { alias: field.alias, searchField: field.searchField };
                          pageReq.sortOrder = "asc";
                          pageReq.sort = JSON.stringify(obj);
                          setReq(pageReq);
                          setPageReq(pageReq)
                        }} className={pageSortParsed?.searchField === field.searchField && pageInfo?.sortOrder === "desc" ? "sort-arrow-down clicked" : "sort-arrow-down"}>
                          <img src="/assets/img/sort-normal.svg" />
                        </span>
                  }
                </span>

                <em>{field.description}</em>
              </>
            )}
          </TableCell>
        );
      })
    );
  };
  const tableBodyRender = () => {
    return (
      <>
        {
          interestLoading ? <TableRow><Loading /></TableRow> :
            getDynamicFieldList && interestTableList?.length !== 0 ?
              interestTableList?.map((data: any, i: any) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <LongMenu options={['Delete', 'Download']}
                      onClick={(e: string) => {
                        menuClick(e, data, i)
                      }} />
                    </TableCell>
                    {getDynamicFieldList?.map((field: any) => {
                      if(field.apiKey === "createdAt") {
                        return <TableCell>{moment(data[field.apiKey]).format("DD/MM/yyyy hh:mm:ss")}</TableCell>;
                      }
                      if(field.apiKey === "fileDate") {
                        return <TableCell>{moment(data[field.apiKey]).format("DD/MM/yyyy")}</TableCell>;
                      }
                      return <TableCell
                      onClick={() => (field.apiKey === "fileName") ? history.push({ pathname: "/brokerdaily-detail", search: 'id=' + data.encId + '&name=' + data[field.apiKey] }) : {}}
                      className={field.apiKey === "fileName" ? "link" : ""}>{data[field.apiKey]}</TableCell>;
                    })}
                  </TableRow>
                );
              }) : <TableRow className="no-records">No data found</TableRow>
        }
      </>
    );
  };

  const filters = ["TEXTFIELD", "SELECT"];
  const addNewCompany = () => {
    history.push({
      pathname: "/add-brokerdaily",
      state: { update: false },
    });
  };

  const globalSearch = () => {
    var dynamicList = getDynamicFieldList?.map((item) => {
      return { alias: item.alias, searchField: item.searchField, inputType: item.inputType }
    })
    var setSearchList = { fields: dynamicList, value: searchText }
    pageReq.searchWithFields = JSON.stringify(setSearchList)
    setReq(pageReq)
    setPageReq(pageReq)
  }
  const multiSelectView = () => {
    return dynamicFieldsList?.getDynamicFields?.fields?.map(
      (tableField: any) => (
        <MenuItem
          key={tableField.description}
          value={tableField.description}
          disabled={tableField.apiKey === "fileName" ? true : false}
        >
          <Checkbox
            checked={
              tableHeader.indexOf(tableField.description) > -1
            }
          />
          <ListItemText primary={tableField.description} />
        </MenuItem>
      )
    )
  }
  const handleMultiSelectView = (value: any) => {
    setTableHeader(value);
  }


  useEffect(() => {

    var updateReq: dynamicFieldRequest = {};
    var updateTableFields = tableHeader?.map((value: any) => {
      return dynamicFieldsList?.getDynamicFields?.fields?.filter((data: any) => {
        return value === data.description;
      }).pop();
    })?.map((eid) => eid?.encId);
    console.log(updateTableFields, "uus")
    updateReq.menuEncId = menuId;

    updateReq.fields = updateTableFields.join();
    updateTableButton({
      variables: {
        menuEncId: updateReq.menuEncId,
        fields: updateReq.fields,
      },
    })

    setReq(pageReq);
  }, [tableHeader])

  const handlePagination = (e: string, value: number) => {
    pageReq.page = value
    setReq(pageReq);
    console.log(pageReq)
  }



  return (
    <React.Fragment>
      <Modal
      modalOpen={modalOpen}
      buttonSave={() => {
        confirmDelete()
        setModalOpen(false)
      }}
      buttonClose={() => {
        setModalOpen(false)
      }}
      modalData={"The selected file will be deleted. Are you sure to proceed?"}
      cancelBtnText = "Cancel"
      saveBtnText = "Delete"
      />
      <ViewComponent
      pageName={"Broker Daily"}
      pageBreadCrumb={"Security Lending"}
      fileSuccess={fileToast}
      fileSuccessMsg={msg}
      history={history}
      filterShow={showFilter}
      filterClick={() => setShowFilter(!showFilter)}
      globalSearchClick={globalSearch}
      loading={mutationLoading}
      globalSearch={(e: string) => {
      setSearchText(e)
      }}
      globalKeyPress={(e: any) => {
      if (e.key === "Enter") {
        globalSearch()
      }
      }}
      multiSelectData={tableHeader}
      handleMultiSelect={(value: any) => {
      setFilterLoading(true)
      handleMultiSelectView(value)

      setTimeout(() => {
        setFilterLoading(false)
      }, 1000)
      }}
      multiSelectView={multiSelectView()}
      addNewButton={addNewCompany}
      addNewButtonName={"Upload new file"}
      showAddBtn={true}
      tableHeaderRender={tableHeaderRender()}
      tableBodyRender={tableBodyRender()}
      showEmptyCell={true}
      paginationTotalpage={0}
      paginationHandleMethod={(e: string, value: number) => { handlePagination(e, value) }}
      filterCount={localFilter.filter((item) => item.value).length}
      filterData={getDynamicFieldList}
      filterSubmit={(data: fields[]) => {
      setLocalFilters(JSON.stringify(data))
      pageReq.filter = JSON.stringify(data);
      setReq(pageReq);
      }}
      closeFilter={() => { setShowFilter(!showFilter) }}
      // key={key}
      chipData={chipData}
      clearAllFilter={() => {
      getDynamicFieldList.map((item) => {
        delete item.value
        delete item.name
      })
      chipData = []
      pageReq.filter = ""
      setLocalFilters("")
      setReq(pageReq)
      // setKey(Math.random().toString()) //changing the key rerenders the input component to reset it
      // props.onSubmit(fieldset)
      }}
      chipDataDelete={(data: any, index: any) => {
      if (getDynamicFieldList) {
        getDynamicFieldList.map((item, idx) => {
          if (data.key == item.encId) {
            delete item.value
            delete localFilter[idx].value
          }

          chipData = chipData.filter((chip) => chip.key !== data.key)



        })

        // setChipData(tmpchipData)
        pageReq.filter = JSON.stringify(localFilter)
        setLocalFilters(pageReq.filter)
        setReq(pageReq)
      }
      }}
      pageRecords={(val) => {
        pageReq.pageSize = val;
        setPageSize(val)
        setReq(pageReq)
      }}
      />
    </React.Fragment>
  );
}

export default BrokerDaily;
