
import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';

export const styles  = makeStyles({
  loadingWrap : {
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: "0px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: "1",
              "& ::before":{
                  content: '""',
                  position: 'absolute',
                  top: "0px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  background: 'rgba(00, 00, 00, 0.7)'
              },
              "& .MuiSvgIcon-root":{
                animation: "rotation 0.8s infinite linear",
                  "& path":{
                    fill: theme.white,
                  }
              },
              "&@keyframes rotation" :{
                "0%" :{
                  transform: "rotate(0deg)",
                },
                "100%":{
                  transform: "rotate(359deg)",
                }
              }
      
      },
     
      // parent : {
      //       '@media (max-width: 1024px)' : {
      //           background: "transparent"  
      //       },
      //       background : "transparent"
      // },
      // error : {
      //       color: "red"
      // }
  })

  // export const style = {
  //     parent : {
  //           background : "white"
  //     }
  // }

  