import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
// import "./style.css";
import {styles} from "./style";


interface radioProps {
  items?: string[];
  name?: string;
  onChange?: (val: string) => void;
}

export default function RadioButton(props: radioProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  const classes = styles()
  //console.log("classes",classes)

  return (
    <div className={classes.parent}>
      <FormControl component="fieldset" >
        <RadioGroup
         
          aria-label={props.name}
          name={props.name}
          
          onChange={handleChange}
        >
          {props.items?.map((item, i) => {
            return (
              <FormControlLabel  className={classes.root} value={item} control={<Radio color={'primary'}  classes={{}}/>} label={item} />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
}
