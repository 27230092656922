import { gql } from '@apollo/client'

export const LOGIN = gql`mutation($AuthenticateUser: AuthenticateUser){
    authenticate(auth: $AuthenticateUser) {
        accessToken
        accessToken
        tokenType
        expiresIn
        refreshToken
    }
}`




export {}