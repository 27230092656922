import { gql } from '@apollo/client'


export const DAILYINTERESTLIST = gql `query($pageRequest: pageRequest) {
  getAllDailyInterests(pageRequest: $pageRequest) {
      dailyInterestList {
          encId
          fileName
          fileDate
          vendorName
          type    
          uploadedBy
          createdAt
      }
      pageInfo {
          page
          pageSize
          totalPage
          totalRecords
          sort
          sortOrder
      }
  }
}`

export const DAILYINTEREST_DETAILLIST = gql `query($req: DailyInterestDetailInput) {
    getAllDailyInterestDetails(dailyInterestDetailInput: $req) {
        dailyInterestDetailList {
            encId
            secIdLink
            symbol
            date    
            quantity
            amount
            loanRate
            customerSplitRate
            commissionSplitRate
            customerRevenue
            commissionRevenue
        }
        pageInfo {
            page
            pageSize
            totalPage
            totalRecords
            sort
            sortOrder
        }
    }
}`

export const DYNAMIC_FIELDS = gql`
query ($screenType: String, $menuEncId: String) {
  getDynamicFields(screenType: $screenType, menuEncId: $menuEncId) {
      fields {
          encId
          description
          inputType
          alias
          reference
          field
          apiKey
          sortReq
          ordering
      }
      checkedFields {
          encId
          description
          inputType
          alias
          reference
          field
          apiKey
          sortReq
          ordering
      }
  }
}
`
export const MENULIST = gql`
      query {
        getAllMenu {
            encId
            menu
            id
        }
      }
`

// export const COUNTRYLIST = gql`
// query($pageRequest:pageRequest){
//   getAllCountry(pageRequest : $pageRequest){
//       countryName,
//       countryCode,
//       encId
//   }
//   }
// `;

// export const STATELIST = gql`
// query($pageRequest:pageRequest){
//   getAllState(pageRequest : $pageRequest){
//     stateName
//     stateCode
//     encId
//   }
// }
// `;

// export const CITYLIST = gql`
// query($pageRequest:pageRequest){
//   getAllCity(pageRequest : $pageRequest){
//     cityName
//     cityCode
//     encId
//   }
// }

// `;

// export const GET_COMPANY_DETAILS  = gql`
// query($encId:String){
//   getCompanyDetail (encId: $encId ){
//   companyName
//   companyCode
//   encId
//   dtcNumber
//   taxId
//   mpid
//   address {
//   encId
//   addressLine1
//   addressLine2
//   city{
//   encId
//   cityName
//   }
//   state {
//   encId
//   stateName
//   }
//   country {
//   encId
//   countryName
//   }
//   phoneMobile
//   zipcode
//   fax
//   email
//   contact
//   }
//   }
//   }
// `;
  
