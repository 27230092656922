import React from 'react';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {styles} from "./style";


// import "./style.css";
import Buttons from '../button';

interface multiSelectProps {
  multiSelectValue?: React.ReactNode;
  onHandleMultiselectValue?: (value: any) => void;
  multiSelectValues?: string[]
  label?:string
  count?:number
}


export default function MultiSelect(props:multiSelectProps) {
  
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      if(props.onHandleMultiselectValue){
        props.onHandleMultiselectValue(event.target.value as string[])
      }
  };



  const classes = styles()

 
  return (
    <div className={classes.multiSelectWrap}>
      <FormControl>
        <InputLabel id="demo-mutiple-checkbox-label">{props.label ? props.label : "View Column"}{ props.count ? " ("+ props.count + ")":""}</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={props.multiSelectValues}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => ('')}
          MenuProps={{ classes: { paper: classes.customDropdown } }}
        >
          {props.multiSelectValue ? props.multiSelectValue: 'No data found'}
        </Select>
        </FormControl>
    </div>
  );
}
