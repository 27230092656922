import {screens} from "./interface";
import {ROUTES} from "../../routes"

export interface menu {
    screens?:screens[]
}
export interface menuTitle {
    title?:string
    normalImage?:string
    hoverImage?:string
    activeImage?:string
}
export const menuTitle : menuTitle[] = [
   {
       title:"Security Lending",
       normalImage: "/assets/img/menu-1.svg",
       hoverImage:"/assets/img/menu-hover-1.svg",
       activeImage:"/assets/img/menu-active-1.svg"
   },
   {
    title:"System-Wide Master Data",
    normalImage: "/assets/img/menu-2.svg",
    hoverImage:"/assets/img/menu-hover-2.svg",
    activeImage:"/assets/img/menu-active-2.svg"
  },{
    title:"Security Master Data",
    normalImage: "/assets/img/menu-3.svg",
    hoverImage:"/assets/img/menu-hover-3.svg",
    activeImage:"/assets/img/menu-active-3.svg"
  },
  {
    title:"Transactions",
    normalImage: "/assets/img/menu-4.svg",
    hoverImage:"/assets/img/menu-hover-4.svg",
    activeImage:"/assets/img/menu-active-4.svg"
  },
  {
    title:"Accounting",
    normalImage: "/assets/img/menu-5.svg",
    hoverImage:"/assets/img/menu-hover-5.svg",
    activeImage:"/assets/img/menu-active-5.svg"
  },
  {
    title:"Reconciliations",
    normalImage: "/assets/img/menu-6.svg",
    hoverImage:"/assets/img/menu-hover-6.svg",
    activeImage:"/assets/img/menu-active-6.svg"
  },
  {
    title:"Coporate Actions",
    normalImage: "/assets/img/menu-7.svg",
    hoverImage:"/assets/img/menu-hover-7.svg",
    activeImage:"/assets/img/menu-active-7.svg"
  },
  {
    title:"Transactions",
    normalImage: "/assets/img/menu-8.svg",
    hoverImage:"/assets/img/menu-hover-8.svg",
    activeImage:"/assets/img/menu-active-8.svg"
  },
  {
    title:"Margin",
    normalImage: "/assets/img/menu-9.svg",
    hoverImage:"/assets/img/menu-hover-9.svg",
    activeImage:"/assets/img/menu-active-9.svg"
  },
  {
    title:"ACATS & Transfers",
    normalImage: "/assets/img/menu-10.svg",
    hoverImage:"/assets/img/menu-hover-10.svg",
    activeImage:"/assets/img/menu-active-10.svg"
  },
  {
    title:"Security & Audit",
    normalImage: "/assets/img/menu-11.svg",
    hoverImage:"/assets/img/menu-hover-11.svg",
    activeImage:"/assets/img/menu-active-11.svg"
  }
]

export const SECURITY_LENDING: screens[] = [
        {
          title: "Broker Daily",
          path: ROUTES.BROKER_DAILY
        },
        {
          title: "Positions",
          path: ROUTES.POSITIONS
        }
]

// export const SYSTEM_WIDE_MASTER_DATA: screens[] = [
//     {
//         title: "broker-daily",
//         path: ROUTES.BROKER_DAILY
//     }
// ]

export const menus : menu[] = [
    {
      screens: SECURITY_LENDING 
    }]
