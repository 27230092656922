import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';
export const styles  = makeStyles({
    switchButtonWrap:{
        "& label":{
            fontSize: theme.smallSize,
            display:"block",
            color:theme.inputLabel,
            marginBottom: "3px",
        },
        "& .MuiToggleButtonGroup-root":{
            background: "transparent",
            borderRadius: "25px",
            padding:"3px",
            border:"1px solid" + theme.inputBorder,
            "& .MuiToggleButton-root":{
                padding: "0px 20px",
                border:"0",
                fontSize:theme.smallSize,
                borderRadius:"25px",
                textTransform:"capitalize",
                fontFamily: 'Space Grotesk!important',
                color:theme.white,
                "&:hover":{
                    background:theme.primaryColor,
                },
                "&.Mui-selected":{
                    background:theme.primaryColor,
                    color:theme.white,
                }
            }
        }
    }
});