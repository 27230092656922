import TextField from "@material-ui/core/TextField";
import { useRef } from "react";
// import "./style.css";
import {styles, style} from "./style"

interface inputProps {
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  label?: string;
  maxLength?: number;
  defaultValue?: string;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  multiline?: boolean;
  rowsMax?: number;
  fieldError?:string;
  onChange?: (e: string) => void;
  onkeypress?: (e: any) => void;
  inputRef?: any
  ref?:any
  key?:string
  searchClick?: ()=> void;
}

export default function InputFieldSearch(props: inputProps) {
  function onChangeValue(e: React.ChangeEvent<HTMLInputElement>) {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  }
  function onsearchClick() {
    if (props.searchClick) {
      props.searchClick();
    }
  }

  const classes = styles()

  return (
    <div className={classes.root}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        key={props.key}
        className="input-field"
        required={props.required}
        error={props.error}
        label={props.label}
        autoComplete={"off"}
        value={props.value}
        placeholder={props.placeholder}
        disabled={props.disabled}
        type={props.type}
        inputProps={{ maxLength: props.maxLength }}
        defaultValue={props.defaultValue}
        helperText={props.errorText}
        multiline={props.multiline}
        inputRef={props.inputRef}
        rowsMax={props.rowsMax} //this is used when multiline is true
        onChange={onChangeValue}
        ref={props.ref}
      />
      <i className="search-icon"><img src="/assets/img/search-icon.svg" onClick={onsearchClick}/></i>
      <span className="error-msg">{props?.fieldError ? props?.fieldError:''}</span>
    </div>
  );
}
