
import { useQuery } from "@apollo/client";
import {Table, Textfield, Chip, Buttons, Select, Checkbox, RadioButton, 
  AutoComplete, BasicPagination, Modal, MultiSelect, InputFieldSearch, Snackbars, DatePickers, FormSelect, InputUpload, Switch, LongMenu,MenuListComposition} from "../components/UI-kit";

import { useState } from "react";
interface home {
  title: String;
}

function Home({ title = "drive wealth" }: home) {
 
  const selectItems = [{"value" : 10,"label":"Ten"},{"value" : 20,"label":"Twenty"},{"value" : 30,"label":"Thirty"}]
  
  const [value,setValue] = useState<string>()



  const renderText = (val: string) => {
      return  <Textfield
      error={true}
      errorText={"error !!!"}
      required={true}
      label={"name"}
      value={val}
      onChange={(val: string) => {
       setValue(val)
      }}
    />
  }
  // model[0].companyName
  return (
    <div>
      {title}
      {renderText(value!)}
      {/* <Buttons
        label={"primary"}
        type={"primary"}
        onClick={toggleDarkMode}
      />
      <Buttons
        label={"secondary"}
        type={"secondary"}
        onClick={() => {
          // console.log("change data---->",data);
          setValue("")
        }}
      />
      <Buttons
        label={"Disabled"}
        type={"primary"}
        disabled={true}
        onClick={() => {
          console.log("clicked!!!",value);

         
        }}
      />
      {/* <Select label={"Age"} items={selectItems} onChange={(val)=>{console.log("val",val)}}/> }
      <Checkbox
        onChange={(e) => {
          console.log(e.target.name);
        }}
        label={"Checkbox"}
        name={"Checkbox1"}
      />
    
      <Chip /> 
      <Table />
      <BasicPagination/>
      <Modal/>
      <MultiSelect/> */}
      <AutoComplete />  
      {/* <InputFieldSearch/>
      <Snackbars/> */}
        <RadioButton
        items={["male", "female"]}
        name={"gender"}
        onChange={(val) => {
          console.log("val", val);
        }}
      />
      <DatePickers/>
      <FormSelect/>
      <InputUpload fileUploaded={true} closeUpload={()=>{}}/>
      <Switch/>
      <Modal/>
      <LongMenu/>
      <MenuListComposition/>
    </div>
  );
}

export default Home;
