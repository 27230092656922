import { makeStyles } from '@material-ui/core';
import * as theme from '../../../global';
export const styles  = makeStyles({
      menuWrap : {
            width: "300px",
            background: "transparent",
            position: "absolute",
            height: "calc(100vh - 20px)",
            top: "10px",
            left: "10px",
            overflow: "hidden",
            "&::before":{
              background: theme.white,
              width: "80px",
              borderRadius: "13px",
              height: "100%",
              content: '""',
              position: "absolute",
              overflow: "hidden",
            },
            "& .MuiToolbar-regular":{
              position: "absolute",
              top: "0px",
              background: theme.white,
              "z-index": "3",
              padding: "0 10px",
              left: "10px",
              width: "50px",
              borderTopLeftRadius: "13px",
              borderTopRightRadius: "13px",
              justifyContent: "center",
            },
            "& .MuiAppBar-root":{
              background: "transparent",
              width: "70px",
              left: "0",
              margin: "10px",
              borderBottomLeftRadius: "13px",
              borderBottomRightRadius: "13px",
              textAlign: "center",
              boxShadow: "none",
              padding: "0px !important",
              "z-index": "2",
              position: "relative",
              top: "50px",
              "& .MuiToolbar-gutters":{
                padding: "0px 18px",
              },
              "& .menu-list": {
                padding: "0px",
                marginTop: "35px",
                "& li":{
                  listStyleType: "none",
                  padding: "8px 0",
                  margin: "10px 0",
                  cursor: "pointer",
                  position: "relative",
                  height: "30px",
                  "& em":{
                    fontStyle: "normal",
                    position: "relative",
                    display: "inline-flex",
                    left: "10px",
                    padding: "5px 0",
                    borderRadius: "15px",
                    width: "70px",
                    transition: "all 0.2s ease-out",
                    whiteSpace: "nowrap",
                    "& strong":{
                      fontSize: "0",
                      marginLeft: "10px",
                      visibility: "hidden",
                      color: theme.black,
                      lineHeight:"22px",
                    },
                    "& img":{
                      marginLeft: "10px",
                      "&.normal":{
                        display: "block",
                      },
                      "&.hover":{
                        display: "none",
                      },
                      "&.active":{
                        display: "none",
                      }
                    }      
                  },
                  "&:hover":{
                    "& em":{
                      background: theme.orange,
                      width: "220px",
                      left: "10px",
                      transition: "all 0.2s ease-out",
                      "& strong":{
                       fontSize: "13px",
                       visibility: "visible",
                      },
                      "& img":{
                        "&.hover":{
                          display: "block",
                        },
                        "&.normal":{
                          display: "none",
                        },
                        "&.active":{
                          display: "none",
                        }
                      }
                    }
                  },
                  "&.active":{
                    background: theme.mainBg,
                    borderTopLeftRadius: "30px",
                    borderBottomLeftRadius: "30px",
                    "& span":{
                      position: "absolute",
                      "z-index": "-1",
                     "&.upper-curve":{
                        content: '""',
                        background: theme.mainBg,
                        width: "40px",
                        height: "40px",
                        right: "0",
                        top: "-40px",
                        "&::before":{
                          content: '""',
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          background: theme.white,
                          borderBottomRightRadius: "20px",
                          top: "0px",
                          right: "0px",
                        }
                      },
                      "&.bottom-curve":{
                        content: '""',
                        background: theme.mainBg,
                        width: "40px",
                        height:"40px",
                        right: "0",
                        bottom: "-40px",
                        "&::before":{
                          content: '""',
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          background: theme.white,
                          borderTopRightRadius: "20px",
                          bottom: "0px",
                          right: "0px",
                        }
                      }
                    },
                    "& img":{
                      "&.hover":{
                        display: "none",
                      },
                      "&.active":{
                        display: "block",
                      },
                      "&.normal":{
                        display: "none",
                      }
                    },
                    // "&:hover":{
                    //   "& strong":{
                    //     display: "none",
                    //   },
                    //   "& em":{
                    //     background: "transparent",
                    //   }
                    // }
                  }
                }
              },
              "& .user-settings":{
                position:"sticky",
                bottom:"0px",
                padding:"15px 5px",
                // borderTop:"1px solid" + theme.inputBorder,
                borderTop:"1px solid #d5d5d5",
                marginLeft:"-10px",
                background:theme.white,
                borderBottomLeftRadius:"13px",
                borderBottomRightRadius:"13px",
              }
            },
            "& .sub-menubar":{
              width: "200px",
              background: "#1D252E",
              boxShadow:"8px 0px 6px #f5ca5610",
              height: "100vh",
              position: "absolute",
              "z-index": "1",
              marginLeft: "80px",
              padding: "30px 0",
              top: "0",
              "& .sub-menu-list":{
                padding: "0",
                marginLeft: "20px",
                "& li":{
                  listStyleType: "none",
                  margin: "5px 0",
                  "& a":{
                    textDecoration: "none",
                    color: theme.white,
                    fontSize:theme.smallSize,
                    padding: "10px",
                    display: "block",
                    fontWeight: "500",
                    "&:hover":{
                      background: theme.orange,
                      color: theme.black,
                      borderTopLeftRadius: "15px",
                      borderBottomLeftRadius: "15px",
                      fontWeight: "700",
                    }
                  }
                }
              },
              "& strong":{
                marginLeft: "20px",
                color: "#fff",
                display: "block",
                fontSize: theme.normalSize,
                position: "relative",
                marginBottom: "30px",
                "&::after":{
                  content: '""',
                  position: "absolute",
                  height: "1px",
                  width: "50px",
                  background: theme.white,
                  bottom: "-10px",
                  left: "0",
                }
              },
              "& .close-icon":{
                position: "absolute",
                top: "5px",
                right: "10px",
                cursor: "pointer",
              }
            },
            "& div.ps__rail-y":{
                left: "0",
                display: "none",
              }
      },
      selectedMenu : {
        background: theme.orange,
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
        "& a":{
          color: theme.black + "!important",
          fontWeight: "700 !important",
        }
      }
});
