import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {styles, style} from "./style";

// import './style.css';
interface checkboxProps {
  checked?: boolean
  onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void,
  label?:string,
  name?:string
}


export default function CheckboxLabels(props: checkboxProps) {
    const classes = styles();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    };
  
    return (
      <div className="checkbox-wrap">
        <FormControlLabel className={classes.parent}
          control={<Checkbox checked={props.checked} onChange={props.onChange} name={props.name} className={classes.root}/>}
          label={props.label}
        />
      </div>
    )
}