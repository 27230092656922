import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import './style.css'
import { Buttons } from '../../UI-kit';
import {styles} from './style';
import {fullContent} from '../../../global';
import clsx from 'clsx';

interface footerProps{
    onCancel?: ()=> void,
    onSave?: ()=> void,
    buttonSaveName?: string,
    buttonCancelName?: string,
    buttonDisabled?: boolean,
    buttonType?:string,
    cancelButtonType?:string

}
function FooterComponent(props:footerProps){
    const classes = styles();
    const fullwidth = fullContent();
    return(
        <Grid item xs={11} className={clsx(classes.footerSection, fullwidth.fullContent)}>
            <Grid item xs={6} className="footer-left"></Grid>
            <Grid item xs={6} className="footer-right">
                <Buttons 
                    label={props.buttonCancelName}
                    onClick={props.onCancel}
                    type={props.cancelButtonType}
                />
                <Buttons 
                    label={props.buttonSaveName}
                    onClick={props.onSave}
                    type={props.buttonType}
                    disabled={props.buttonDisabled}
                />
            </Grid>
        </Grid>
    )
}

export default FooterComponent;
