import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {styles} from './style';

interface Switch{
    selectSwitch?: string
    switchHandle?: (e:any)=> void
}
export default function SwitchButton(props:Switch){
    const [alignment, setAlignment] = React.useState<string | null>('left');
    const [selected, setSelected] = React.useState(true);
    const classes = styles();

    // const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    //   setAlignment(newAlignment);
    // };
    return(
        <div className={classes.switchButtonWrap}>
            <label>Settlement Holiday*</label>
        <ToggleButtonGroup
            value={alignment}
            exclusive
            aria-label="text alignment"
            //onChange={(e) => props.switchHandle ? props.switchHandle(e): {}}

            >
            <ToggleButton        
                onClick={() => props.switchHandle ? props.switchHandle('Yes'): {}}     
                value="Yes" aria-label="left aligned"  selected ={props?.selectSwitch === "Yes" ? true : false } >
                Yes
            </ToggleButton>
            <ToggleButton 
                onClick={() => props.switchHandle ? props.switchHandle('No'): {}}     
                value="No" aria-label="centered"  selected ={props?.selectSwitch === "No" ? true : false } >
                No
            </ToggleButton>
    </ToggleButtonGroup>
        </div>
    )
}