import TextField from "@material-ui/core/TextField";
import { useRef } from "react";
import {styles, style} from "./style";
// import "./style.css";
interface inputProps {
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  label?: string;
  maxLength?: number;
  defaultValue?: string;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  multiline?: boolean;
  rowsMax?: number;
  fieldError?:string;
  onChange?: (e: string) => void;
  inputRef?: any
  ref?:any
  key?:string
  margin?:any
  id?:string
  name?:string
  changedInput?:string
  minLength?:number,
  inputType?:string
}

export default function InputField(props: inputProps) {
  function onChangeValue(e: React.ChangeEvent<HTMLInputElement>) {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  }
  const classes = styles();
  return (
    <div className={classes.inputWrap}>
      <TextField
        // id="outlined-basic"
        variant="outlined"
        margin={props.margin}
        key={props.key}
        required={props.required}
        error={props.error}
        label={props.label}
        autoComplete={"off"}
        value={props.value}
        placeholder={props.placeholder}
        disabled={props.disabled}
        type={props.type}
        inputProps={{ maxLength: props.maxLength }}
        defaultValue={props.defaultValue}
        helperText={props.errorText}
        multiline={props.multiline}
        inputRef={props.inputRef}
        rowsMax={props.rowsMax} //this is used when multiline is true
        onChange={onChangeValue}
        ref={props.ref}
        id={props.id}
        name={props.name}
      />
      <span className="error-msg">{props?.fieldError ? props?.fieldError:''}</span>
      <span className="input-check"><img src="/assets/img/input-check.svg"/></span>
    </div>
  );
}
