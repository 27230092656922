import React from 'react';
import {styles} from './style';
import clsx from 'clsx';
interface fileUpload{
    fileUploaded?:boolean
    fileName?: string
    fileUploadlabel?:string
    closeUpload?: ()=> void
    onChange?: (e:any)=>void
    download?:()=>void
    fileUploadErr?:boolean
    editDefaultFile?:boolean
    editChangeFile?:boolean
}
export default function InputUpload(props:fileUpload){
    console.log(props)
    const classes = styles();
    return(
        <div className={ 
            props.fileUploadErr ? clsx(classes.uploadWrap, classes.afterUpload, classes.uploadError) 
            : (props.fileUploaded && props.editDefaultFile === false && props.editChangeFile === false) 
            ? clsx(classes.afterUpload) 
            : (props.editDefaultFile === true && props.editChangeFile === false) 
            ? classes.Edit 
            : (props.editChangeFile === true && props.editDefaultFile ===true )
            ? classes.EditUpload : 
            classes.uploadWrap 
            } >
            {/* afterUpload */}
            <div className="file-upload-label">
               <label>{props.fileUploadlabel}</label>
            </div>
            {
               !props.fileUploaded ? 
                <>
                    <input type="file" onChange={(e)=>props.onChange? props.onChange(e): {}}/>
                    <label>
                        <strong><svg viewBox="0 0 27.196 30.49" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                            </defs>
                            <path className="a" transform="translate(896.81 -465.11)" d="M-877.891,482.949v-.566c0-2.768-.012-5.536.012-8.3,0-.469-.145-.572-.581-.566-1.725.022-3.452,0-5.177.018-.437.006-.552-.14-.547-.562.021-1.758,0-3.517.012-5.276,0-.31-.059-.474-.428-.473-3.272.012-6.545.008-9.817.009a1.907,1.907,0,0,0-.227.031c-.012.125-.033.249-.033.372q0,11.089-.013,22.178c0,.489.224.51.592.51,5.226-.006,10.452,0,15.678-.015.434,0,.578.106.537.541-.039.42.013.848-.022,1.268-.009.113-.18.227-.3.311-.054.038-.158.006-.239.006q-8.889,0-17.778.009c-.443,0-.588-.1-.587-.568q.02-13.092,0-26.184c0-.484.149-.586.613-.584q6.447.028,12.894,0a1.311,1.311,0,0,1,1.03.412c2.036,2.061,4.094,4.1,6.127,6.162a1.193,1.193,0,0,1,.323.748q.034,4.1,0,8.207a1.189,1.189,0,0,1-.292.715c-.516.579-1.078,1.118-1.622,1.673Zm-4.185-14.108c0,.817-.008,1.588.014,2.358,0,.068.2.184.31.188.519.019,1.039.01,1.559,0,.21,0,.42-.018.661-.03Z"/>
                            <path className="a" transform="translate(741 -630.69)" d="M-713.805,653.806c-.248.015-.4.031-.559.032-.911,0-1.822,0-2.733,0-.824,0-.8-.143-.8.824,0,1.985-.011,3.969.01,5.954,0,.421-.1.6-.541.561a5.659,5.659,0,0,0-1.023,0c-.436.039-.56-.14-.556-.56.016-2.082-.005-4.165.017-6.247,0-.427-.1-.559-.537-.544-.974.035-1.951.012-2.927.01-.172,0-.344-.014-.622-.026.154-.194.237-.323.343-.43,1.489-1.5,2.985-2.993,4.469-4.5.218-.221.368-.3.628-.039,1.507,1.529,3.03,3.043,4.544,4.565A3.632,3.632,0,0,1-713.805,653.806Z"/>
                        </svg>
                        </strong>
                        <em>Click here</em>
                        <span>To upload file<i>(File Format : pdf)</i></span>
                    </label>
                </> 
           : <div className="file-upload">
               <i className="download-icon" onClick={()=>props.download ? props.download():{}}>
               <svg viewBox="0 0 17.494 15.94" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(-338 -537)">
                    <path className="a" transform="translate(796.91 175.96)" d="M-447.963,372.69v-1.457h.284c.8,0,1.593.015,2.389-.011a2.52,2.52,0,0,0,2.407-2.607,2.534,2.534,0,0,0-2.522-2.474c-.336,0-.672.071-1.043.113-.05-.277-.1-.547-.149-.817a3.606,3.606,0,0,0-2.942-2.894,3.585,3.585,0,0,0-3.687,1.656.845.845,0,0,1-.921.493,3.256,3.256,0,0,0-3.283,2.917,3.27,3.27,0,0,0,3.328,3.621c.574,0,1.149,0,1.739,0v1.454c-1.444,0-2.894.154-4.222-.651a4.778,4.778,0,0,1-2.2-5.158,4.759,4.759,0,0,1,4.255-3.648.453.453,0,0,0,.285-.156,4.968,4.968,0,0,1,4.962-1.952,4.876,4.876,0,0,1,3.942,3.377.271.271,0,0,0,.272.2,3.984,3.984,0,0,1,3.566,3.187,4,4,0,0,1-3.685,4.794C-446.1,372.724-447.028,372.69-447.963,372.69Z"/>
                    <path className="a" transform="translate(744.58 111.63)" d="m-400.64 438.49h2.063v-5.816h1.466v5.815h2.11l-2.822 2.821z"/>
                    </g>
             </svg>
               </i>
              <i className="delete-icon" onClick={()=>props.closeUpload ? props.closeUpload():{}}>
               <svg enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="m424 64h-88v-16c0-26.467-21.533-48-48-48h-64c-26.467 0-48 21.533-48 48v16h-88c-22.056 0-40 17.944-40 40v56c0 8.836 7.164 16 16 16h8.744l13.823 290.28c1.221 25.636 22.281 45.717 47.945 45.717h242.98c25.665 0 46.725-20.081 47.945-45.717l13.823-290.28h8.744c8.836 0 16-7.164 16-16v-56c0-22.056-17.944-40-40-40zm-216-16c0-8.822 7.178-16 16-16h64c8.822 0 16 7.178 16 16v16h-96zm-128 56c0-4.411 3.589-8 8-8h336c4.411 0 8 3.589 8 8v40h-352zm313.47 360.76c-0.407 8.545-7.427 15.239-15.981 15.239h-242.98c-8.555 0-15.575-6.694-15.981-15.239l-13.751-288.76h302.44z"/>
                    <path d="m256 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z"/>
                    <path d="m336 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z"/>
                    <path d="m176 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z"/>
                </svg>
               </i>
               <strong>
               <svg viewBox="0 0 22.631 28.266" xmlns="http://www.w3.org/2000/svg">
                        <g transform="translate(-277.24 -511)">
                        <g transform="translate(277.24 511)">
                        <path className="a" transform="translate(593.67 -497.75)" d="M-593.664,511.874q0-5.547,0-11.094a2.848,2.848,0,0,1,2.145-2.936,3.616,3.616,0,0,1,.878-.087c3.565-.006,7.13-.01,10.695.009a1.135,1.135,0,0,1,.722.294q3.958,3.919,7.876,7.879a1.135,1.135,0,0,1,.3.721q.025,8.136.012,16.272a2.85,2.85,0,0,1-3.065,3.083q-8.255.006-16.51,0a2.843,2.843,0,0,1-3.051-3.047Q-593.671,517.421-593.664,511.874Zm2.865-11.285v22.578H-573.9V507.621h-7.064v-7.032Z"/>
                        <path className="a" transform="translate(571.54 -536.02)" d="M-559.018,549.6c-.177.718-.366,1.433-.521,2.155a1.832,1.832,0,0,0,.057.578.181.181,0,0,0,.037.07c.261.386.433.9.809,1.119.342.2.907.078,1.37.123a5.528,5.528,0,0,1,1.12.179.951.951,0,0,1,.716,1.327,1.244,1.244,0,0,1-1.523.8,3.812,3.812,0,0,1-1.726-1.089.736.736,0,0,0-.736-.206,2.6,2.6,0,0,0-2.284,1.385,4.372,4.372,0,0,1-1,1.193,2.541,2.541,0,0,1-.851.486,1.056,1.056,0,0,1-1.171-.219.934.934,0,0,1-.253-1.213,3.025,3.025,0,0,1,.941-.968,13.77,13.77,0,0,1,1.975-.876.792.792,0,0,0,.529-.448,3.378,3.378,0,0,1,.2-.427,2.35,2.35,0,0,0,.087-2.654,4.145,4.145,0,0,1-.449-1.682,1.209,1.209,0,0,1,.652-1.338,1.309,1.309,0,0,1,1.9,1.03,6.083,6.083,0,0,1,.049.659Z"/>
                        </g>
                        </g>
               </svg>
               </strong>
               <span className="file-name">{props.fileName ? props.fileName : "Upload file name"}</span>
           </div>
            }
           
           
        </div>
    )
}