import { useQuery } from "@apollo/client";
import { allMenu, dynamicFieldRequest, dynamicFields, getPosition, getBrokerDailyDetail, pageRequest } from "./interface";
import { POSITIONLIST, DAILYINTEREST_DETAILLIST, DYNAMIC_FIELDS, MENULIST } from "./query" ;
import { DELETE_DAILY_INTEREST } from "./mutation" ;


export var fieldReq: dynamicFieldRequest = {}
export var errorText: string = ""
export var searchText: string = ""
export var pageReques: pageRequest = {}
export function setSearchText(value: string) {
    searchText = value
}

export function setPageReq(req: pageRequest) {
    pageReques = req
}
export function setErrorText(value: string) {
    console.log("in toast", value)
    errorText = value
}

export function GetAllPosition(pageReq: any, getDynamicFieldList: any) {
    /* query to get list of all postion with pagination */
    const { data: allPositionList, loading: interestLoading, error: Error , refetch } = useQuery<getPosition>(POSITIONLIST,
        {
            variables: { pageRequest: pageReq },
            fetchPolicy: 'network-only'
        });
    return { allPositionList, interestLoading , refetch}
}

export function GetDailyInterestDetails(pageReq: any, pageParam: any, getDynamicFieldList: any) {
    /* query to get list of all broker daily details with pagination */
    const obj = {pageRequest: pageReq, ...pageParam};
    console.log('obj', obj);
    const { data: dailyInterestDetailList, loading: interestDetailLoading, error: Error } = useQuery<getBrokerDailyDetail>(DAILYINTEREST_DETAILLIST,
        {
            variables: { req: obj },
            fetchPolicy: 'network-only'
        });
    return { dailyInterestDetailList, interestDetailLoading }
}

export function GetMenuList(menuName: string){
    const {data:menu, loading:loading2, error: menuError } = useQuery<allMenu>(MENULIST,{
        // fetchPolicy:'network-only'
    });
    // menuList = menu
    if(menu?.getAllMenu) {
        fieldReq.menuEncId = menu?.getAllMenu.filter((item)=>item.menu == menuName)[0].encId
        fieldReq.screenType = "LIST"
    }
    return fieldReq;
}


export function GetDynamicFields() {
    const { data: fields, loading: loading3, error: dynamicError } = useQuery<dynamicFields>(DYNAMIC_FIELDS, {
        skip: !fieldReq.menuEncId, //skip the query if menuId is null
        variables: { screenType: fieldReq.screenType, menuEncId: fieldReq.menuEncId },
        fetchPolicy: 'network-only'
    })
    return fields

}

// export function DeleteDailyInterest(){
//     const {data: fields, loading: loading3} = useQuery
// }