import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';

export const styles  = makeStyles({
    tabWrap : {
            "& .MuiAppBar-colorPrimary":{
                background: 'transparent',
                "& .MuiTab-root":{
                   backgroundColor: theme.tabColor,
                   color: theme.boldText,
                }
            }
      
      },
  })


  