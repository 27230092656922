import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { autoCompleteOptions } from '../../../pages/brokerDaily/interface';
// import "./style.css";
import {styles} from './style';

interface searchProps {
  value?: string
  placeholder?: string
  disabled?: boolean
  type?: string
  label?: string
  maxLength?: number
  defaultValue?: string
  required?: boolean
  error?: boolean
  errorText?: string
  multiline?: boolean
  rowsMax?: number
  searchData?: autoCompleteOptions[]
  onChange?: (e: string,idx:number) => void
  hideSearchIcon?: boolean
  inputRef?:any
  key?:any
  id?:string
  changedInput?:string
  textFieldOnChange?:(e: string) => void
}

export default function mAutoComplete(props: searchProps) {
  
  function onChangeValue(e:  React.ChangeEvent<{}>, value: string) {
    console.log('changed input', e,value)
    if (props.onChange) {
      var selectedIdx : number = 0
      props.searchData?.map((item,idx)=>{
        if(item.label == value) {
            selectedIdx = idx
            return
        }
      })
    
      props.onChange(value,selectedIdx);
    }
  }

  function textChange(e:  React.ChangeEvent<HTMLInputElement>) {
    if (props.textFieldOnChange) {
      props.textFieldOnChange(e.target.value)      
      
    }
  }

  let classes = styles()
  return (
    <div className="search-wrap">
      <Autocomplete
        id={props.id}
        disabled={props.disabled}
        onChange={(e,value,details)=>onChangeValue(e,value)}
        options={props.searchData ? props.searchData.map((item:any)=> item?.label) : []}
        // key={props.key}
        defaultValue={props.defaultValue}
        className={classes.autoCompleteWrap}
        //autoSelect={true}
        //inputValue={props.defaultValue}
        value={props.value}
        // getOptionLabel={(option)=> option.label ? option.label : ""}
        // getOptionSelected={(option, value) => {
        //   return true
        // }}
        // onChange={(e:any) => onChangeValue(e)}
       
      // style={{ width: 300 }}
      renderInput={(params) => (
          <TextField {...params} inputRef={props.inputRef} label={props.label}  margin="normal" variant="outlined" 
          className={props.changedInput ? props.changedInput : ( props.value || props.defaultValue ? "activeField" : "")} onChange={textChange} 
          />
        )}
    />
     {/* <Autocomplete
        id="free-solo-demo"
        onChange={(e,value,details)=>onChangeValue(e,value)}
        options={props.searchData ? props.searchData.map((item:any)=> item?.label) : []}
        key={props.key}
        defaultValue={props.defaultValue}
        autoSelect={true}
        //inputValue={props.defaultValue}
        value={props.value}
        // getOptionLabel={(option)=> option.label ? option.label : ""}
        getOptionSelected={(option, value) => {
          console.log(option, value)
          return true
        }}
        // onChange={(e:any) => onChangeValue(e)}
        renderInput={(params) => (
          <TextField {...params} inputRef={props.inputRef} placeholder={props.label} margin="normal" variant="outlined" 
            
          />
        )}
      /> */}
      {/* {
        !props.hideSearchIcon &&  <i className="search-icon"><img src="/assets/img/search-icon.svg"/></i>
      } */}
      
     
    </div>
    
  );
}
