import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';

export const styles  = makeStyles({
    btnWrap : {
        display: "inline-flex",
        "&:nth-of-type(2)" :{
            marginLeft: "15px",
        },
        "& .MuiButtonBase-root":{
            fontFamily: 'Space Grotesk!important',
        },
        "& .MuiButton-containedPrimary.primary-btn":{
            backgroundColor: theme.buttonPrimary,
            borderRadius: "15px",
            height: "34px",
            boxShadow: "0px 3px 6px #00000029",
            color: theme.black,
            minWidth: "86px",
            textTransform: "none",
            "&:hover":{
                backgroundColor:theme.buttonPrimary,
            },
            "& .MuiButton-label":{
                fontSize: theme.normalSize,
                "& .MuiButton-iconSizeMedium":{
                    fontSize:"0px",
                }
            }
         },
         "& .MuiButton-containedSecondary.secondary-btn":{
            backgroundColor: theme.mainBg,
            borderRadius: "15px",
            height: "34px",
            color: theme.white,
            border:"1px solid" + theme.buttonPrimary,
            minWidth: "86px",
            textTransform: "none",
            "&:hover":{
                backgroundColor: theme.mainBg,
            },
            "& .MuiButton-label":{
                fontSize: theme.normalSize,
                "& .MuiButton-iconSizeMedium":{
                    fontSize:"0px",
                }
            },
            "&.teritery-btn":{
                background: theme.primaryColor,
                color:theme.white,
                border:"1px solid" + theme.primaryColor,
            }
        },
        "& .MuiButton-contained.Mui-disabled":{
            borderRadius: "15px",
            cursor: "not-allowed",
            opacity: "0.5",
            pointerEvents: "auto",
            "& .MuiButton-label":{
                fontSize: theme.normalSize,
                "& .MuiButton-iconSizeMedium > *:first-child":{
                    fontSize:"0px",
                }
            }
        },
      }
});

export const style = {
    addBtn :{
        background:theme.primaryColor,
    },
}