import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import {InputFieldSearch, MultiSelect, Buttons, Table, BasicPagination,Chip, Select, LongMenu,MenuListComposition,Snackbars} from "../components/UI-kit";
import Header from "../components/common/header";
import {styles} from "./style/style";
import { ChipData, fields } from "./brokerDaily/interface";
import { useHistory } from "react-router-dom";
import FilterComponent from "../components/common/filter/filter";
import LastVisitedComponent from "../components/common/lastVisited";
interface commonView{
  pageName?: string
  filterShow?: boolean,
  filterClick?: () => void
  globalSearchClick?: () => void
  globalSearch?: (val:string) => void
  globalKeyPress?: (e:string) => void
  searchValue?:string
  multiSelectData?: string[]
  handleMultiSelect?: (value:string) => void
  multiSelectView?: React.ReactNode
  addNewButton?: () => void
  addNewButtonName?: string
  tableHeaderRender?: React.ReactNode
  tableBodyRender?: React.ReactNode
  paginationTotalpage?:number
  paginationHandleMethod?: (e: string, value: number)=>void
  filterData?: any
  filterSubmit?: (data: fields[]) => void
  closeFilter?: () => void
  chipData?: any
  chipDataDelete?: (data:any,index:any) => void
  pageRecords?: (val:any) => void
  filterCount?:number
  history?:any
  fileSuccess?:boolean
  fileSuccessMsg?:string
  fileToastHandler?:()=>void
  loading?:boolean
  clearAllFilter?:()=>void
  pageBreadCrumb?: string
  breadCrumbUrl?: string
  subBreadCrumbUrl?: string
  showEmptyCell?: boolean
  showAddBtn?: boolean
}
function CommonView(props:commonView) {
  const classes = styles();
  const [showMenu,setShowMenu] = useState<boolean>(false);
  const [key,setKey] = useState("sdsdfds")
  const [filterLoading,setFilterLoading] = useState(false)
  const pageRecord = [{value:10, label:10},{value:15, label:15}, {value:20, label:20}]
  const history = useHistory();
    
     
  return (
    <div className={classes.companyWrap}> 
    <div className="flex-wrap">
    {/* flex-wrap */}
      <Header showMenu={(show)=>setShowMenu(show)}/>
      <Grid className="main-content-wrapper" item xs={showMenu ? 9 : 11}>
        <Grid container spacing={2} className="company-section">
          <div className="top-section full-content">
            <Grid item xs={6}>
              <h1 className="main-heading">
                <strong>{props?.pageName}</strong>
                <span className="breadcrumb pointer" onClick={() => {
                  if(props?.breadCrumbUrl) {
                    history.push("/" + props?.breadCrumbUrl);
                  }
                  }}>{props?.pageBreadCrumb}</span>
                  {props?.subBreadCrumbUrl ? <span className="breadcrumb">{props?.subBreadCrumbUrl}</span>: null}
              </h1>
            </Grid>
            <LastVisitedComponent history={props.history}/>
            
          </div>
            { 
            props.fileSuccess &&
            <Snackbars errorMsg={props.fileSuccessMsg} openToast={props.fileSuccess} type={"success"} autoHideDuration={2000}
            toastClose={()=>props.fileToastHandler? props.fileToastHandler(): {}}/>
           }
          <div className="filter-section full-content">
            <Grid item xs={6} className="inner-filter">
                {!props.filterShow && (
                    <Grid >
                    <span
                        className={props.filterCount ? "filter-icon filter-clicked" : "filter-icon"}
                        onClick={props.filterClick}>
                        <i className="filter-icon-menu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.56" height="9.707" viewBox="0 0 14.56 9.707"><path className="a" d="M5.662,9.707V8.088H8.9V9.707ZM2.426,5.662V4.045h9.707V5.662ZM0,1.617V0H14.56V1.617Z"/></svg>
                        </i>
                        Filter By
                        {
                          props.filterCount != 0 &&  <em className="filter-count">{props.filterCount}</em>
                        }
                       
                    </span>
                </Grid>
                )}
              <Grid className="input-wrap-search">
                <InputFieldSearch 
                    placeholder={"Search..."}
                    searchClick={props.globalSearchClick}
                    onChange={(val:string)=> props.globalSearch ? props.globalSearch(val) : {}}
                  />
              </Grid>
            </Grid>
            
            <Grid item xs={6} className="sort-options">
                <MultiSelect
                    multiSelectValues={props.multiSelectData}
                    onHandleMultiselectValue={(value: any) => props.handleMultiSelect ? props.handleMultiSelect(value) : {} }
                    multiSelectValue={props.multiSelectView}
                    count={props.multiSelectData?.length}
                    label={"View Column"}
                  />
                  {props.showAddBtn ? 
                  <Buttons
                    label={props.addNewButtonName}
                    type="teritery"
                    onClick={props.addNewButton}
                    icon= {"/assets/img/plus-icon.svg"}
                  /> : null}
                  <LongMenu options={['Export']}/>
            </Grid>
          </div>
          {  !props.filterShow && (props.chipData && props.chipData.length != 0) &&
          <Grid item xs={12} className="chosen-filter">
                  <Grid item xs={12} className="last-visited">
                  <Chip 
                  data={props.chipData} 
                  onDelete={(data,index)=>props.chipDataDelete ? props.chipDataDelete(data,index) : {} }/>
                  </Grid>
          </Grid>
          }
          <div className="main-content full-content">
          {props.filterShow && (
              <FilterComponent
                loading={filterLoading || props.loading}
                data={props.filterData}
                onSubmit={(data: fields[])=>props.filterSubmit ? props.filterSubmit(data) : {}}
                multiSelectData={props.multiSelectData}
                multiSelectView={props.multiSelectView}
                onClose={props.closeFilter}
                // assetList={props.assetList}
                handleMultiSelect={(value: any) => {
                  if(props.handleMultiSelect) {
                    setFilterLoading(true)
                    props.handleMultiSelect(value)
                    setTimeout(()=>{
                      setFilterLoading(false)
                    },250)
                  }
                 }}
              />
            )}
            <Grid className="main-table-section" item xs={props.filterShow ? 9 : 12}>
                <Table
                    tableHeader={props.tableHeaderRender}
                    tableBody={props.tableBodyRender}
                    filterShow={props.filterCount != 0}
                    showEmptycell={props.showEmptyCell}
                  />
                <div className="table-footer full-content">
                <Grid item xs={6} className="left-section">
                  <span>View Last<Select 
                  defaultValue={10}
                  menuList={pageRecord} onChange={(val)=> props.pageRecords ? props.pageRecords(val) : {}}/> Records</span>
                </Grid>
                <Grid item xs={6} className="right-section">
                <BasicPagination
                    totalPage={props.paginationTotalpage}
                    handleChange={(e: string, value: number) => props.paginationHandleMethod ? props.paginationHandleMethod(e,value) : {} }
                  />
                </Grid>
              </div>
            </Grid>
          </div>

        </Grid>
        </Grid>
    </div>
    </div>
  );
}

export default CommonView;
