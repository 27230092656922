import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from '../../../global';
export const styles  = makeStyles({
      root : {
        "& .MuiCheckbox-colorSecondary.Mui-checked":{
            color: theme.primaryColor,
        },
      },
      parent  :{
            display: "inline-flex",
            borderBottom: "0px",
            boxShadow: "none",
            padding: "0px",
            background: "transparent",
            margin: "10px 0",
            "& li":{
                listStyleType: "none",
                marginRight: "8px",
                "& .MuiChip-root":{
                    backgroundColor:theme.mainBg,
                    border:"1px solid" + theme.chipBorder,
                    height: "25px",
                    "& .MuiChip-label":{
                        fontSize: theme.smallSize,
                        color: theme.white,
                        fontWeight: "400",
                        fontFamily: 'Space Grotesk!important',
                        textTransform:"capitalize",
                    }
                },
                "& :last-child" :{
                    marginRight: "0px",
                },
                "& .MuiChip-deleteIcon":{
                    color: theme.inputFocusColor,
                    fontSize:theme.smallSize,
                    height: "20px",
                    width: "20px",
                    marginRight:"5px",
                }
            }
        }
});

export const style = {
    parent : {
          background : "white"
    }
}