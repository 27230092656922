import { makeStyles, withStyles } from '@material-ui/core';
import * as theme from './../../global';
export const styles  = makeStyles({
      companyWrap : {
        "& .company-section":{
            padding: "10px 20px",
            "z-index": "1",
            position: "relative",
            "& .top-section":{
                "& .last-visited":{
                    textAlign: "right",
                    display:"flex",
                    justifyContent:"flex-end",
                    alignItems:"center",
                    zIndex:"9",
                    "& strong":{
                        fontSize: "12px",
                        color: theme.white,
                        fontWeight: "500",
                        marginRight: "10px",
                    },
                    "& .chip-wrap":{
                        maxWidth: "85%",
                        "& ul li .MuiChip-root":{
                             border:"1px solid" + theme.white,
                             cursor:"pointer",
                        }
                    }
                }
            },
            "& .filter-section":{
                position: "relative",
                "& .inner-filter":{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    "& .filter-icon":{
                        color: theme.orange,
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: theme.normalSize,
                        fontWeight: "500",
                        position: "relative",
                        marginRight:"20px",
                        "& i":{
                            cursor: "pointer",
                            marginRight: "5px",
                            "& svg path":{
                                fill: theme.white,
                            }
                        },
                        "&.filter-clicked":{
                            background: theme.inputFocusColor,
                            color: theme.black,
                            padding: "8px 12px",
                            borderTopLeftRadius: "3px",
                            borderTopRightRadius: "3px",
                            "& i":{
                                "& svg path":{
                                    fill: theme.black,
                                }
                            }
                        },
                        "& .filter-count":{
                            background: theme.primaryColor,
                            color: theme.white,
                            position: "absolute",
                            right: "5px",
                            top: "-10px",
                            fontSize: theme.smallSize,
                            fontStyle: "normal",
                            width: "18px",
                            height: "18px",
                            borderRadius: "100%",
                            justifyContent: "center",
                            display: "inline-flex",
                            alignItems: "center",
                        }
                    },
                    "& .input-wrap-search":{
                        maxWidth: "60%",
                        flexBasis: "60%",
                        paddingLeft:"0px"
                    }
                },
                "& .sort-options":{
                    justifyContent: "flex-end",
                    display: "flex",
                    "& .select-wrap":{
                        display: "inline-flex",
                        width: "30%",
                    },
                    "&.btn-wrapper":{
                        display: "inline-flex",
                        alignItems: "center",
                        "&.MuiButton-containedPrimary.primary-btn":{
                            background: theme.primaryColor,
                            color: theme.blueBtnText,
                           "&::before":{
                                content: '""',
                                position: "absolute",
                                left: "10px",
                                top: "5px",
                                height: "15px",
                                width: "15px",
                            },
                            "& .MuiButton-startIcon":{
                                "& .MuiIcon-root":{
                                    display: "inline-flex",
                                    alignItems: "center",
                                }
                            }
                        }
                    }
                },
                "& i.close-icon":{
                    position: "absolute",
                    right:"15px",
                    top: "5px",
                    cursor: "pointer",
                },
                "& .filter-bottom":{
                    display:"block",
                    textAlign:"center",
                }
            },
            "& .chosen-filter":{
                border:"1px solid" + theme.selectBorder,
                padding: "0px 10px",
                boxShadow: "0px 6px 10px #000000A6",
                borderRadius: "5px",
                marginTop: "10px",
                position:"relative",
                "& .clear-all":{
                    position:"absolute",
                    right: "15px",
                    top:"15px",
                    textDecoration:"underline",
                    cursor:"pointer",
                    color:theme.white,
                    fontSize:"11px",
                }
            },
            "& .main-content":{
                marginTop: "15px",
                alignItems: "flex-start",
                justifyContent: "space-between",
                "& .filter-wrapper":{
                    background: theme.mainBg,
                    border: "1px solid" + theme.commonBorder,
                    maxWidth: "23%",
                    flexBasis: "23%",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 25px #00000066",
                    height: 'calc(100vh - 126px)',
                    // transition: all ease-in-out 0.1s,
                    "& .filter-section" :{
                        padding: "15px",
                    },
                    "& b":{
                        width: "100%",
                        display: "block",
                        marginBottom: "10px",
                        color: theme.white,
                        fontSize: theme.normalSize,
                    },
                    "& .filetr-option":{
                        justifyContent: "space-between",
                        "& span":{
                            fontSize: theme.smallSize,
                            textDecoration: "underline",
                            cursor: "pointer",
                            "&.clear-filter":{
                                color: theme.white,
                            },
                            "&.add-filter":{
                                color: theme.white,
                                position:"relative",
                                    "& .MuiFormControl-root":{
                                        background: "transparent",
                                        width:"160px",
                                        "& .MuiFormLabel-root":{
                                            textDecoration:"underline",
                                            right:"0px",
                                            left:"auto",
                                            transform:"translate(0, 5px) scale(1)",
                                        },
                                        "& .MuiInputBase-root":{
                                            height:"auto",
                                            padding:"0px",
                                            border:"0px",
                                            boxShadow:"none",
                                            "&::before":{
                                                display:"none",
                                            }
                                        }
                                    },
                                    "& .MuiSelect-icon":{
                                        display:"none",
                                    }
                            },
                        }
                    },
                    "& ul":{
                        padding: 0,
                        "& li":{
                            listStyleType: "none",
                            marginBottom: "15px",
                        }
                    }
                },
                "& .main-table-section":{
                     transition:" 0.1s all ease-in-out",
                     border:"1px solid" + theme.commonBorder,
                     borderRadius:"5px",
                }
            }
        },
        "& .table-footer":{
            background: theme.mainBg,
            boxShadow: "0px -3px 6px #000000BF",
            bottom: "0",
            padding: "10px 0",
            position:"relative",
            borderBottomLeftRadius:"5px",
            borderBottomRightRadius:"5px",
            "& .left-section":{
                color: theme.white,
                fontSize: theme.normalSize,
                padding: "0px 15px",
                "& .select-wrap":{
                    display: "inline-flex",
                }
            },
            "& .right-section":{
                "& .MuiPagination-ul":{
                    textAlign: "right",
                    justifyContent: "flex-end",
                }
            }
        },
        "& .no-records":{
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            margin: "auto",
            textAlign: "center",
            height: "20px",
            fontSize: "14px",
            color: theme.white,
            background: "transparent !important",
            "& .MuiTableCell-body":{
                width: "100%",
                textAlign: "center",
                display: "block",
                background: "transparent",
                border: "0",
                "&:before":{
                    display:"none",
                }
            },
            "&:hover":{
                boxShadow:"none !important",
                "& td" :{
                    border:"0px !important",
                    background:"transparent",
                }
            }
            }
        },
        /*add-company-styles*/
        addCompanyWrap :{
            "& .add-company-section" :{
                padding: "12px 20px",
                zIndex: "1",
                position: "relative",
                "& .top-section":{
                    "& .last-visited":{
                        textAlign: "right",
                        display:"flex",
                        justifyContent:"flex-end",
                        alignItems:"center",
                        " & strong":{
                            fontSize: "12px",
                            color: theme.white,
                            marginRight: "10px"
                        },
                        "& .chip-wrap":{
                            maxWidth: "85%",
                            "& ul li .MuiChip-root":{
                                 border:"1px solid" + theme.white,
                                 cursor:"pointer",
                            }
                        }
                    }
                },
                "& .main-content" :{
                    "& .form-extra-info":{
                        padding:"0px",
                        margin:"0px",
                        "& .mandatory-text" :{
                            fontSize: theme.smallSize,
                            fontWeight: "700",
                            marginTop: "5px",
                            color: theme.white,
                            listStyleType:"none",
                            display:"inline-flex",
                            paddingRight:"15px",
                            alignItems:"center",
                            verticalAlign:"middle",
                            "& img":{
                                marginRight:"5px",
                            },
                            "&.changes":{
                                position:"relative",
                                paddingLeft:"35px",
                                "&::before":{
                                    content:'""',
                                    position:"absolute",
                                    left:"0px",
                                    width:"25px",
                                    height:"8px",
                                    border:"1px solid" + theme.snackSuccess,
                                    borderRadius:"3px",
                                }
                            }
                        },
                    },
                    "& .add-page-scroll":{
                        maxHeight: "calc(100vh - 145px)",
                        width:"100%",
                    },
                    "& .form-section.full-content" :{
                        alignItems: "flex-start",
                        marginTop: "30px",
                        marginBottom:"30px",
                        "& .label-section" :{
                            maxWidth:"12%",
                            flexBasis:"12%",
                            "& label":{
                                fontSize: theme.headingSize,
                                color: theme.greyHeading,
                                fontWeight: "700"
                            }
                        },
                        "&:last-child":{
                            marginBottom:"80px",
                        }
                    },
                    "& .input-form-section" :{
                        maxWidth:"87%",
                        flexBasis:"87%",
                        boxShadow: "0px 0px 25px#00000066",
                        borderRadius: "5px",
                        padding: "10px 0",
                        "& .input-wrapper":{
                            padding:"13px 15px",
                            maxWidth: "33.3%",
                            flexBasis: "33.3%",
                            "&.document-wrapper":{
                                maxWidth: "20%",
                                flexBasis: "20%",
                            },
                        },
                        "& .MuiPaper-elevation1":{
                            boxShadow: "none"
                        },
                        "& .search-wrap":{
                        "& .search-icon":{
                                top: "12px"
                            }
                            // .MuiFormControl-marginNormal{
                            //     margin:0px;
                            //     .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment{
                            //         display: none;
                            //     }
                            // }
                        }
                    }
                }
            }
        }
});
